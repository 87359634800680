import { IPanel, getPanelLODTitle, getPanelTypeTitle } from "../entities/panel"
import Typography from "@weave-design/typography";
import Button from "@weave-design/button";
import { PanelTitle } from './panelTitle';
import "bootstrap/dist/css/bootstrap.min.css"
import "./panels.css";

interface IComponentProps {
    panel: IPanel;
    hideEditButtons: boolean;
    select: () => void;
    deletePanel: () => void;
    updateInstancesCount: (newInstancesCount: number) => void;
}

export const PanelCard = ({ panel, select, deletePanel, hideEditButtons }: IComponentProps) => {
    return (<div className="col-lg-3 col-md-4 col-sm-6 col-12 pb-3">
        <div className="card">
            <img className="card-img-top" src={panel.previewImageUrl} alt="" style={{ cursor: "pointer" }} onClick={() => select()} />
            <div className="card-body">
                <Typography className="card-title"><PanelTitle panel={panel} /></Typography>
                <Typography className="card-text">LOD: {getPanelLODTitle(panel)}</Typography>
                <Typography className="card-text">Type: {getPanelTypeTitle(panel)}</Typography>
                <Typography className="card-text">Type id: {panel.typeName}</Typography>
                {panel.isInstancePanel && <Typography>Element id: {panel.instanceName}</Typography>}
                <Typography className="card-text">Created at: {new Date(panel.createdAt).toLocaleString()}</Typography>
            </div>
            <div className="card-footer">
                <Button type="primary" size="standard" title="View" onClick={() => select()} />
                {!hideEditButtons && <>
                    <div style={{ width: "10px", display: "inline-block" }}></div>
                    <a href={panel.downloadUrl} className="btn btn-sm btn-outline-secondary" style={{ paddingTop: "7px", paddingBottom: "7px", marginBottom: "1px" }}>Download</a>
                    <div style={{ width: "10px", display: "inline-block" }}></div>
                    <button className="btn btn-sm btn-outline-secondary" style={{ height: "37px" }} onClick={() => deletePanel()} title="Delete panel">
                        <img src="/trash.svg" alt="" />
                    </button>
                </>}
            </div>
        </div>
    </div>)
}