import { connect } from 'react-redux';
import { showReport } from '../actions/reportsActions';
import { getReport, isReportDialogVisible, State } from "../reducers/mainReducer";
import Modal from "@weave-design/modal";

type Props = {
    visible: boolean;
    report: string | null;
    showReport: (visibility: false) => void;
}

const FolderReportDialog = ({ visible, report, showReport }: Props) => {
    if (!visible)
        return (null);

    const modalStyles = (styles: any) => ({
        ...styles,
        modal: {
            ...styles.modal,
            window: {
                ...styles.modal.window,
                width: "700px",
                height: "700px"
            }
        }
    });

    return (<Modal
        open={true}
        title="Folder failing panels report"
        onCloseClick={() => showReport(false)}
        stylesheet={modalStyles}>
            <pre style={{width: "100%", overflow: "revert"}}>
                {report}
            </pre>
    </Modal>)
}

export default connect(function (store: State) {
    return {
        visible: isReportDialogVisible(store),
        report: getReport(store)
    };
}, { showReport })(FolderReportDialog);