import { getUserData, State } from '../reducers/mainReducer';
import { storeToken, cleanToken, loadProfile } from './profileActions'
import repo from '../Repository';
import { BasicItemResponse } from '../entities/responses';

export const actionTypes = {
    EDIT_LOGIN_FORM_MESSAGE: "EDIT_LOGIN_FORM_MESSAGE",
    EDIT_PASSWORD_FORM_MESSAGE: "EDIT_PASSWORD_FORM_MESSAGE",
    SHOW_LOGIN_FAILED: "SHOW_LOGIN_FAILED"
} as const

type EditLoginType = {
    type: "EDIT_LOGIN_FORM_MESSAGE";
    login: string;
}

type EditPasswordType = {
    type: "EDIT_PASSWORD_FORM_MESSAGE";
    password: string;
}

type ShowLoginFailedType = {
    type: "SHOW_LOGIN_FAILED";
    message: string;
}

export type LoginActionType = EditLoginType | EditPasswordType | ShowLoginFailedType;

export const editLogin = (newLogin: string): EditLoginType => {
    return {
        type: "EDIT_LOGIN_FORM_MESSAGE",
        login: newLogin
    }
}

export const editPassword = (newPassword: string): EditPasswordType => {
    return {
        type: "EDIT_PASSWORD_FORM_MESSAGE",
        password: newPassword
    }
}

export const showLoginFailed = (message: string): ShowLoginFailedType => {
    return {
        type: actionTypes.SHOW_LOGIN_FAILED,
        message
    };
};

export const tryLogin = () => async (dispatch: (type: any) => void, getState: () => State) => {
    var userData = getUserData(getState());

    let loginAttemptResult: BasicItemResponse<string>;

    try {
        loginAttemptResult = await repo.login(userData);
    } catch (e) {
        dispatch(showLoginFailed("Application error occured. Try later, please"));

        return;
    }

    if (loginAttemptResult === null)
        return;

    if (!loginAttemptResult.isSuccess) {
        dispatch(showLoginFailed(loginAttemptResult.message));

        return;
    }

    const token = loginAttemptResult.item;

    repo.setAccessToken(token);
    storeToken(token);

    dispatch(loadProfile());
}

export const logout = () => {
    cleanToken();

    window.location.reload();
}