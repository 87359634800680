import Toggle from "@weave-design/toggle";

export type Viewport = "Model" | "Inspector";

type Props = {
    title: string;
    viewport: Viewport;
    setViewport: (viewport: Viewport) => void;
}

export const PanelViewportSwitcher = ({ title, viewport, setViewport }: Props) => {
    return <div style={{display: "flex", flexDirection: "row", width: 400, justifyContent: "space-between"}}>
        <div>{title}</div>
        <div style={{ display: "flex" }}>
            Engineering
            <Toggle 
                on={viewport === "Inspector"}
                onChange={(x: boolean) => setViewport(x ? "Inspector" : "Model")}
                stylesheet={toggleViewportStylesheet}/>
            Production
        </div>
    </div>
}

const toggleViewportStylesheet = (styles: any) => ({
    ...styles,
    toggleWrapper: {
        ...styles.toggleWrapper,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 8,
        cursor: "pointer"
    },
    toggleInput: {
        ...styles.toggleInput,
        cursor: "pointer"
    }
});