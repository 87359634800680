import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import Label from '@weave-design/label';
import Input from '@weave-design/input';
import Button from '@weave-design/button';
import Typography from "@weave-design/typography";
import IconButton from '@weave-design/icon-button';
import Checkbox from "@weave-design/checkbox";
import Spacer from '@weave-design/spacer';
import { Folder24 } from '@weave-design/icons';
import merge from "lodash.merge";

import { showUploadModelSource, selectModelSourceFile, setProductionLod, uploadNewPanelModel } from "../actions/panelAutocadSourceModelActions";
import { isModelUploadDialogVisible, getModelSource, getNewModelCreationError, isCornerModelUpload, State } from "../reducers/mainReducer";

import "./models.css"

interface IComponentProps {
    isVisible: boolean;
    isCornerModel: boolean;
    productionLod: boolean;
    errorMessage: string | null;
    showUploadModelSource: (visible: boolean, cornerModel?: boolean) => void;
    setProductionLod: (value: boolean) => void;
    uploadNewPanelModel: (file: File) => void;
}

interface IComponentState {
    file: File | null;
}

export class UploadForm extends Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);

        this.state = {
            file: null
        };
    }

    componentDidUpdate(prevProps: IComponentProps) {
        if (prevProps.isVisible === this.props.isVisible)
            return;

        this.setState({ ...this.state, file: null });
    }

    render() {
        if (!this.props.isVisible)
            return (null);

        const modalStyles = (styles: any) =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "400px",
                        height: "280px",
                        top: "calc(50% - 140px)"
                    },
                    bodyContent: {
                        overflow: "hidden" // no scrollbar
                    }
                }
            });

        const checkBoxStyles = (styles: any) => {
            return { ...styles, checkboxWrapper: { ...styles.checkboxWrapper, paddingTop: "3px", marginRight: "3px" } };
        }

        const fileName = (this.state.file?.name || "").toLowerCase();

        const fileInputElement = document.getElementById("modelFileInput");

        if (fileName === "" && fileInputElement instanceof HTMLInputElement)
            fileInputElement.value = "";

        const hasUpload = fileName.endsWith('.dwg');

        const templateUrl = this.props.isCornerModel
            ? "https://dextall-panels.storage.googleapis.com/samples/CornerPanelSample.dwg"
            : "https://dextall-panels.storage.googleapis.com/samples/PanelSample.dwg";

        const acmTemplateUrl = this.props.isCornerModel
            ? "https://dextall-panels.storage.googleapis.com/samples/CornerPanelSample_ACM.dwg"
            : "https://dextall-panels.storage.googleapis.com/samples/PanelSample_ACM.dwg";

        return (<React.Fragment>
            <Modal
                open={this.props.isVisible}
                title={`New ${this.props.isCornerModel ? "corner " : ""}panel`}
                onCloseClick={() => this.props.showUploadModelSource(false)}
                stylesheet={modalStyles}>
                <div id="upload-file-form">
                    <div className="fileBrowseContainer">
                        <div className="stretch">
                            <Label
                                variant="top"
                                disabled={false} >
                                Panel dwg file:
                            </Label>
                            <Input
                                variant="box"
                                value={fileName}
                                disabled={true}
                            />
                        </div>
                        <div className="browseButton">
                            <label htmlFor="modelFileInput">
                                <IconButton
                                    icon={<Folder24 />}
                                    title="Choose panel dwg file"
                                    onClick={() => { document.getElementById("modelFileInput")?.click(); }}
                                />
                            </label>
                            <input id="modelFileInput"
                                type="file"
                                accept=".dwg"
                                onChange={(e) => {
                                    this.onPackageFileChange(e);
                                }}
                            />
                        </div>
                    </div>

                    <div className="stretch">
                        <Checkbox stylesheet={checkBoxStyles} checked={this.props.productionLod} onChange={(x: boolean) => this.props.setProductionLod(x)} /><Label>Production LOD</Label>
                    </div>

                    <div style={{ paddingTop: "4px" }}>
                        <Typography>Samples: <a href={templateUrl} target="_blank" rel="noreferrer">sheet metal</a> and <a href={acmTemplateUrl} target="_blank" rel="noreferrer">ACM</a></Typography>
                    </div>

                    {this.props.errorMessage !== null && <Typography children={this.props.errorMessage} className="creation-failed-message" />}

                    {this.props.errorMessage === null && <Spacer spacing="l" />}

                    <div className="buttonsContainer">
                        <Button
                            size="standard"
                            title="Create"
                            type="primary"
                            onClick={() => this.uploadPanelModel()}
                            disabled={!hasUpload} />
                        <div style={{ width: '14px' }} />
                        <Button
                            id="cancel_button"
                            size="standard"
                            title="Cancel"
                            type="secondary"
                            onClick={() => { this.props.showUploadModelSource(false); }} />
                    </div>
                </div>
            </Modal>
        </React.Fragment>)
    }

    private onPackageFileChange(data: React.ChangeEvent<HTMLInputElement>) {
        const files = data.target.files;

        if (files && files.length > 0) {
            this.setState({ ...this.state, file: files[0] });
        }
    }

    private uploadPanelModel() {
        if (!this.state.file)
            return;

        this.props.uploadNewPanelModel(this.state.file);
    }
}

export default connect(function (store: State) {
    return {
        isVisible: isModelUploadDialogVisible(store),
        productionLod: getModelSource(store).productionLod,
        errorMessage: getNewModelCreationError(store),
        isCornerModel: isCornerModelUpload(store)
    }
}, { showUploadModelSource, selectModelSourceFile, uploadNewPanelModel, setProductionLod })(UploadForm)