import { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import { CloseMUI, Error24 } from "@weave-design/icons";
import Typography from "@weave-design/typography";
import './modalFail.css';
import merge from "lodash.merge";
import Button from '@weave-design/button';
import IconButton from "@weave-design/icon-button";
import { closeProcessingErrorDialog } from "../actions/processingActions";
import { isProcessingFailed, getProcessingFailure, getProcessingFailureTitle, State } from "../reducers/mainReducer";

interface IComponentProps {
    isVisible: boolean;
    message: string | null;
    title: string;

    closeProcessingErrorDialog: () => void;
}

export class ProcessingFailure extends Component<IComponentProps> {
    render() {
        if (!this.props.isVisible)
            return (null);

        const modalStyles = (styles: any) =>
            merge(styles, {
                modal: {
                    window: {
                        width: "371px",
                        height: "263px",
                        borderLeftStyle: "solid",
                        borderLeftWidth: "thick",
                        borderLeftColor: "#ec4a41" // by design
                    }
                }
            });

        return (<Modal
            open={true}
            title="Error"
            onCloseClick={() => this.props.closeProcessingErrorDialog()}
            stylesheet={modalStyles}
            headerChildren={
                <header id="customHeader">
                    <div className="customHeaderContent">
                        <div className="title">
                            <Error24 className="errorIcon" />
                            <Typography style={{
                                paddingLeft: "8px",
                                fontSize: "inherit",
                                fontWeight: "inherit",
                                lineHeight: "inherit"
                            }}>Error</Typography>
                        </div>
                        <IconButton style={{ width: "24px", height: "24px", marginLeft: "auto", marginTop: "7px" }}
                            icon={<CloseMUI />}
                            onClick={() => this.props.closeProcessingErrorDialog()}
                            title=""
                        />
                    </div>
                </header>
            }>
            <div className="modalFailContent">
                <div>
                    <Typography><span className="errorMessageTitle">{this.props.title}</span></Typography>
                    <div className="errorMessage">
                        {this.props.message}
                    </div>
                </div>
            </div>
            <div className="modalFailButtonsContainer">
                <Button className="button" style={
                    { width: '102px', height: '36px', borderRadius: '2px', marginLeft: '12px' }}
                    type="primary"
                    size="small"
                    title="Ok"
                    onClick={() => this.props.closeProcessingErrorDialog()}
                />
            </div>
        </Modal>)
    }
}

export default connect(function (store: State) {
    return {
        isVisible: isProcessingFailed(store),
        message: getProcessingFailure(store),
        title: getProcessingFailureTitle(store)
    }
}, { closeProcessingErrorDialog })(ProcessingFailure)