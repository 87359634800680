import { showProcessingPane } from "./processingActions";
import repo from '../Repository';

type ShowReportActionType = {
    type: "FOLDER_REPORTS_SHOW";
    visible: boolean;
    report: string | null;
}

export type ReportsActionsType = ShowReportActionType;

export const showFolderReport = (folderId: string) => async (dispatch: (type: any) => void) => {
    dispatch(showProcessingPane(true));

    const report = await repo.loadUserPanelsStaticsReport(folderId);

    dispatch(showReport(true, report));

    dispatch(showProcessingPane(false));
}

export const showReport = (visible: boolean, report?: string): ShowReportActionType => {
    return {
        type: "FOLDER_REPORTS_SHOW",
        visible,
        report: report || null
    }
}