import { ProfileActionsType } from "../actions/profileActions";

export type State = {
    isLoggedIn: "unknown" | "logged" | "not-logged";
    name: string;
    avatarUrl: string;
    isRobot: boolean;
    isPanelInspectorUser: boolean;
}

export const initialState: State = {
    isLoggedIn: "unknown",
    name: "Anonymous",
    avatarUrl: "profile.svg",
    isRobot: false,
    isPanelInspectorUser: false
};

export const isLoggedIn = function (state: State) {
    return state.isLoggedIn;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: ProfileActionsType): State {
    switch (action.type) {
        case "PROFILE_LOADED": {
            return { isLoggedIn: action.isLoggedIn, name: action.profile.name, avatarUrl: action.profile.avatarUrl, 
                isRobot: action.profile.isRobot, isPanelInspectorUser: action.profile.isPanelInspectorUser };
        }
        default:
            return state;
    }
}