import { BasicResponse } from "../entities/responses";
import { getCurrentFolder, State } from "../reducers/mainReducer";
import { addLog } from "./notificationActions";
import { reportProcessingProgress, showProcessingPane } from "./processingActions";
import { AxiosError } from "axios";
import { selectFolder } from "./panelsActions";
import repo from '../Repository';

type ShowPanelsInstancesCreationDialog = {
    type: "SHOW_PANEL_INSTANCES_CREATION_DIALOG";
    visible: boolean;
}

type ShowPanelsInstancesCreationError = {
    type: "SHOW_PANEL_INSTANCES_CREATION_ERROR";
    message: string | null;
}

export type PanelInstancesActionType = ShowPanelsInstancesCreationDialog | ShowPanelsInstancesCreationError;

export const showPanelsInstancesCreationDialog = (visible: boolean): ShowPanelsInstancesCreationDialog => {
    return {
        type: "SHOW_PANEL_INSTANCES_CREATION_DIALOG",
        visible
    }
}

export const showPanelsInstancesCreationError = (message: string | null): ShowPanelsInstancesCreationError => {
    return {
        type: "SHOW_PANEL_INSTANCES_CREATION_ERROR",
        message
    }
}

export const createPanelsInstances = (file: File) => async (dispatch: (type: any) => void, getState: () => State) => {
    dispatch(addLog("creating panels instances invoked"));

    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Creating panels instances"));

    const state = getState();

    const folder = getCurrentFolder(state)!;

    let createInstancesResponse: BasicResponse;

    try {
        createInstancesResponse = await repo.createPanelInstances(folder.id, file);
    } catch (ex: any) {
        if (ex instanceof AxiosError && ex.response?.data?.message)
            createInstancesResponse = ex.response.data;
        else
            createInstancesResponse = { isSuccess: false, message: "Server error. Repeat operation later" }
    }

    dispatch(showProcessingPane(false));

    if (createInstancesResponse.isSuccess)
        dispatch(showPanelsInstancesCreationDialog(false));
    else
        dispatch(showPanelsInstancesCreationError(createInstancesResponse.message));

    dispatch(selectFolder(folder));
}