import Surface from '@hig/surface';
import './App.css'

export const PanelInspectorMobileApp = () => {
    return <Surface className="fullheight" id="main" level={200}>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", margin: 20}}>
            <div style={placeholderDivStyle}>Google play placeholder</div>
            <div style={placeholderDivStyle}>Appstore placeholder</div>
        </div>
    </Surface>
}

const placeholderDivStyle: React.CSSProperties = {
    width: "calc(50% - 20px)",
    height: "100px",
    backgroundColor: "darkgray",
    borderRadius: "10px",
    textAlign: "center"
}