import { IPanel, isCustomPanelType } from "../entities/panel"

type Props = {
    panel: IPanel
}

export const PanelContentDownload = ({ panel }: Props) => {
    return (<>
        <a key="downloadurl" href={panel.downloadUrl} className="btn btn-sm btn-outline-secondary" style={{ paddingTop: "7px", paddingBottom: "7px", marginBottom: "1px" }}>Download</a>

        {!isCustomPanelType(panel.panelType) && <>
            <div className="parametersPreview">
                Source <a href={`/panels/${panel.id}/source-dwg`}>DWG</a>
            </div>

            <div className="parametersPreview">
                Source <a href={`/panels/${panel.id}/inventor`}>Inventor JSON</a>
            </div>
        </>}
    </>)
}