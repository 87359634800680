import { IBomRow } from "../entities/bomRow"

interface IComponentProps {
    item: IBomRow;
    itemNumber: number;
}

export const BomRow = (props: IComponentProps) => {
    const item = props.item;

    return (<tr>
        <td>{props.itemNumber}</td>
        <td>{item.partNumber}</td>
        <td>{item.quantity}</td>
        <td>{item.depth}</td>
        <td>{item.height}</td>
        <td>{item.width}</td>
        <td>{item.length}</td>
        <td>{item.flatPatternLength}</td>
        <td>{item.flatPatternWidth}</td>
        <td>{item.flatPatternArea}</td>
        <td>{item.drawingNumber}</td>
        <td>{item.materialGroup}</td>
        <td>{item.material}</td>
        <td>{item.color}</td>
        <td>{item.gloss}</td>
        <td>{item.thickness}</td>
    </tr>)
}