import * as signalR from '@aspnet/signalr';

export class JobProgress {
    async startConnection() {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl('/signalr/connection')
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        connection.serverTimeoutInMilliseconds = 1200000;

        //@ts-ignore
        await connection.start({ transport: ['webSockets'] });

        return connection;
    }

    async subscribeToProcessModelsEvents(
        onProgress: (panelId: string, message: string) => void, 
        onCompleted: (panelId: string) => void, 
        onFailed: (panelId: string, message: string) => void): Promise<string> {
        const connection = await this.startConnection();

        connection.on("stopListening", () => {
            connection.stop();
        });

        connection.on("onProgress", (panelId, message) => {
            onProgress(panelId, message);
        });

        connection.on("onCompleted", panelId => {
            onCompleted(panelId);
            connection.stop();
        });

        connection.on("onFailed", (panelId, message) => {
            onFailed(panelId, message);
            connection.stop();
        });

        return await connection.invoke("getCurrentConnectionId");
    }
}

export default new JobProgress();