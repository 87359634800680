import { AxiosError } from 'axios';
import { IBomRow } from '../entities/bomRow';
import { IFolder } from '../entities/folder';
import { IPanel, IPanelDrawings } from '../entities/panel';
import { BasicResponse, BasicItemResponse, BasicItemsResponse } from '../entities/responses';
import { State, getCurrentFolder, getPanels } from '../reducers/mainReducer';
import { FolderEditorState } from '../reducers/panelsReducer';
import { addError, addLog } from './notificationActions';
import { reportProcessingProgress, showProcessingPane, showProcessingError } from './processingActions';
import { ProcessingStatus } from '../entities/processingStatus';
import repo from '../Repository';

type SetPanelsType = {
    type: "SET_PANELS";
    panels: IPanel[];
}

type SetFoldersType = {
    type: "SET_FOLDERS";
    folders: IFolder[];
}

type SelectPanelType = {
    type: "SET_SELECTED_PANEL";
    panelId?: string;
}

type SelectFolderType = {
    type: "SET_SELECTED_FOLDER";
    folder: IFolder;
}

type SelectTopFolderType = {
    type: "SET_TOP_FOLDER_SELECTED";
}

type SelectFolderFromStackType = {
    type: "SELECT_FOLDER_FROM_STACK";
    folder: IFolder;
}

type RemovePanelType = {
    type: "REMOVE_PANEL";
    panel: IPanel;
}

type SetFolderEditorStateType = {
    type: "SET_FOLDER_EDITOR_STATE";
    state: FolderEditorState;
}

type SetEditorFolderType = {
    type: "SET_FOLDER_FOR_EDIT";
    folder: IFolder;
}

type SetFolderNameType = {
    type: "SET_FOLDER_NAME";
    folderId: string;
    name: string;
}

type RemoveFolderType = {
    type: "REMOVE_FOLDER";
    folderId: string;
}

type SetPanelDrawingsType = {
    type: "SET_PANEL_DRAWINGS",
    drawings: IPanelDrawings[];
}

type SetPanelInstancesCountType = {
    type: "SET_PANEL_INSTANCES_COUNT";
    panelId: string;
    instancesCount: number;
}

type SetBomExcelFileUrlType = {
    type: "SET_FOLDER_BOM_EXCEL_FILE_URL";
    url: string;
}

type SetBomReportType = {
    type: "SET_BOM_REPORT",
    visible: boolean;
    items: IBomRow[];
}

export type PanelActionTypes = SetPanelsType | SelectPanelType | SetFoldersType | SelectFolderType | SelectTopFolderType
    | SelectFolderFromStackType | RemovePanelType | SetFolderEditorStateType | SetEditorFolderType | SetFolderNameType
    | RemoveFolderType | SetPanelDrawingsType | SetPanelInstancesCountType | SetBomExcelFileUrlType | SetBomReportType;

export const fetchPanels = (folderId: string, openPanelId?: string) => async (dispatch: (type: any) => void, getState: () => State) => {
    dispatch(addLog('Fetch panels invoked'));

    try {
        const panels = await repo.loadPanels(folderId);

        if (getCurrentFolder(getState())?.id !== folderId)
            return;

        dispatch(setPanels(panels));

        dispatch(selectPanel(openPanelId));
    } catch (error) {
        console.log("TODO process error")
        console.log(error);
        dispatch(addError('Failed to fetch panels. (' + error + ')'));
    }

    dispatch(showProcessingPane(false));
}

export const fetchTopFolders = () => async (dispatch: (type: any) => void, getState: () => State) => {
    dispatch(addLog("Fetch top folders invoked"));

    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Loading..."));

    try {
        const loadFoldersResponse = await repo.loadTopFolders();

        if (loadFoldersResponse.isSuccess) {
            if (getCurrentFolder(getState()) === null)
                dispatch(setFolders(loadFoldersResponse.items!));
        }
        else {
            console.log("TODO process error")
            console.log(loadFoldersResponse.message);
        }
    } catch (error) {
        console.log("TODO process error")
        console.log(error);
        dispatch(addError('Failed to fetch panels. (' + error + ')'));
    }

    dispatch(showProcessingPane(false));
}

export const fetchChildrenFolders = (folderId: string) => async (dispatch: (type: any) => void, getState: () => State) => {
    try {
        const loadChildrenFolderResponse = await repo.loadChildrenFolders(folderId);

        if (loadChildrenFolderResponse.isSuccess) {
            if (getCurrentFolder(getState())?.id === folderId) {
                dispatch(setFolders(loadChildrenFolderResponse.items!));

                dispatch(fetchPanels(folderId))
            }
        }
        else {
            console.log("TODO process error")
            console.log(loadChildrenFolderResponse.message);
        }
    } catch (error) {
        console.log("TODO process error")
        console.log(error);
        dispatch(addError('Failed to fetch folders. (' + error + ')'));
    }
}

export const selectFolder = (folder: IFolder) => async (dispatch: (type: any) => void) => {
    dispatch(addLog("Select folder"));

    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Loading..."));

    dispatch(setSelectedFolder(folder));

    dispatch(fetchChildrenFolders(folder.id));
}

export const selectBreadCrumbFolder = (folder: IFolder | null) => async (dispatch: (type: any) => void) => {
    dispatch(addLog("Select bread crumb folder"));

    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Loading..."));

    if (folder !== null) {
        dispatch(selectFolderFromStack(folder));

        dispatch(fetchChildrenFolders(folder.id));
    } else {
        dispatch(selectTopFolder());

        dispatch(fetchTopFolders());
    }
}

export const deletePanel = (panel: IPanel) => async (dispatch: (type: any) => void) => {
    dispatch(addLog("remove the panel"));

    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Removing the panel..."));

    let response: BasicResponse;

    try {
        response = await repo.removePanel(panel.id);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later" };
    }

    if (!response.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(response.message, "Failed to delete the panel"));

        return;
    }

    dispatch(removePanel(panel));
    dispatch(showProcessingPane(false));
}

export const createNewFolder = (parentFolder: IFolder | null, name: string) => async (dispatch: (type: any) => void) => {
    dispatch(addLog("create new folder"));

    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Creating folder..."));

    let response: BasicResponse;

    try {
        response = await repo.createFolder(parentFolder?.id || null, name);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later" };
    }

    if (!response.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(response.message, "Failed to create a new folder"));

        return;
    }

    if (parentFolder !== null)
        dispatch(fetchChildrenFolders(parentFolder.id));
    else
        dispatch(fetchTopFolders());
}

export const updateFolder = (folderId: string, newName: string) => async (dispatch: (type: any) => void) => {
    dispatch(addLog("udpate the folder"));
    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Updating folder..."));

    let response: BasicResponse;

    try {
        response = await repo.updateFolder(folderId, newName);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later" };
    }

    if (!response.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(response.message, "Failed to update the folder"));

        return;
    }

    dispatch(setFolderName(folderId, newName));

    dispatch(showProcessingPane(false));
}

export const deleteFolder = (folderId: string) => async (dispatch: (type: any) => void) => {
    dispatch(addLog("delete the folder"));
    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Deleting the folder..."));

    let response: BasicResponse;

    try {
        response = await repo.removeFolder(folderId);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later" };
    }

    if (!response.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(response.message, "Failed to delete the folder"));

        return;
    }

    dispatch(removeFolder(folderId));

    dispatch(showProcessingPane(false));
}

export const selectPanel = (panelId?: string) => async (dispatch: (type: any) => void) => {
    dispatch(setSelectedPanel(panelId));

    if (!panelId)
        return;

    let response: BasicItemsResponse<IPanelDrawings>;

    try {
        response = await repo.findPanelDrawings(panelId);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later", items: null };
    }

    if (!response.isSuccess) {
        console.log(response.message);

        return;
    }

    dispatch(setDrawings(response.items));
}

export const updatePanelInstancesCount = (panelId: string, instancesCount: number) => async (dispatch: (type: any) => void, getState: () => State) => {
    if (isNaN(instancesCount) || instancesCount < 0)
        return;

    const panel = getPanels(getState()).find(x => x.id === panelId);

    if (!panel)
        return;

    const previousInstancesCount = panel.instancesCount;

    dispatch(setPanelInstancesCount(panelId, instancesCount));

    let response: BasicResponse;

    try {
        response = await repo.updatePanelInstancesCount(panelId, instancesCount);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later" };
    }

    if (!response.isSuccess) {
        console.log(response.message);

        dispatch(setPanelInstancesCount(panelId, previousInstancesCount));
    }
}

export const createExcelBomReport = (folderId: string) => async (dispatch: (type: any) => void) => {
    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Generating Excel BOM file..."));

    let response: BasicItemResponse<string>;

    try {
        response = await repo.createExcelBomReport(folderId);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later", item: null };
    }

    if (!response.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(response.message, "Failed to generate BOM in Excel"));

        return;
    }


    dispatch(setBomExcelFileUrl(response.item));

    dispatch(showProcessingPane(false));
}

export const showFolderBomReport = (folderId: string) => async (dispatch: (type: any) => void) => {
    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Calculating folder BOM..."));

    let response: BasicItemsResponse<IBomRow>;

    try {
        response = await repo.findFolderBom(folderId);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            response = e.response.data;
        else
            response = { isSuccess: false, message: "Server error. Repeat operation later", items: null };
    }

    if (!response.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(response.message, "Failed to calculate folder BOM"));

        return;
    }

    dispatch(setBom(true, response.items));

    dispatch(showProcessingPane(false));
}

export const generatePanelStickyNotes = (folderId: string) => async (dispatch: (type: any) => void) => {
    dispatch(showProcessingPane(true));
    dispatch(reportProcessingProgress("Creating QR codes..."));

    const startQrCodesGenerationResponse = await repo.startPanelsStickyNotesGeneration(folderId);

    if (!startQrCodesGenerationResponse.isSuccess) {
        dispatch(showProcessingPane(false));
        dispatch(showProcessingError(startQrCodesGenerationResponse.message, "QR code generation"));

        return;
    }

    const timeout = 3000;
    const storageId = startQrCodesGenerationResponse.item;

    const validateJob = async () => {
        const statusResponse = await repo.getPanelStickyNotesTaskGenerationStatus(storageId);

        const status = statusResponse.isSuccess ? statusResponse.item : ProcessingStatus.Created;

        if (status === ProcessingStatus.Created) {
            setTimeout(validateJob, timeout);

            return;
        }

        if (status === ProcessingStatus.GenerationFailed) {
            dispatch(showProcessingPane(false));
            dispatch(showProcessingError("Failed to generate QR code", "QR code generation"));

            return;
        }

        const downloadableUrlResponse = await repo.createPanelStickyNotesDownloadableUrl(storageId);

        dispatch(showProcessingPane(false));

        if (!downloadableUrlResponse.isSuccess) {
            dispatch(showProcessingError(downloadableUrlResponse.message, "QR code generation"));

            return;
        }

        dispatch(setBomExcelFileUrl(downloadableUrlResponse.item));
    }

    setTimeout(validateJob, timeout);
}

export const setPanels = (panels: IPanel[]): SetPanelsType => {
    return {
        type: "SET_PANELS",
        panels
    }
}

export const setFolders = (folders: IFolder[]): SetFoldersType => {
    return {
        type: "SET_FOLDERS",
        folders
    }
}

export const setSelectedPanel = (panelId?: string): SelectPanelType => {
    return {
        type: "SET_SELECTED_PANEL",
        panelId
    }
}

export const setSelectedFolder = (folder: IFolder): SelectFolderType => {
    return {
        type: "SET_SELECTED_FOLDER",
        folder
    }
}

export const selectTopFolder = (): SelectTopFolderType => {
    return {
        type: "SET_TOP_FOLDER_SELECTED"
    }
}

export const selectFolderFromStack = (folder: IFolder): SelectFolderFromStackType => {
    return {
        type: "SELECT_FOLDER_FROM_STACK",
        folder
    }
}

export const removePanel = (panel: IPanel): RemovePanelType => {
    return {
        type: "REMOVE_PANEL",
        panel
    }
}

export const setFolderEditorState = (state: FolderEditorState): SetFolderEditorStateType => {
    return {
        type: "SET_FOLDER_EDITOR_STATE",
        state
    }
}

export const editFolder = (folder: IFolder): SetEditorFolderType => {
    return {
        type: "SET_FOLDER_FOR_EDIT",
        folder
    }
}

export const setFolderName = (folderId: string, name: string): SetFolderNameType => {
    return {
        type: "SET_FOLDER_NAME",
        folderId,
        name
    }
}

export const removeFolder = (folderId: string): RemoveFolderType => {
    return {
        type: "REMOVE_FOLDER",
        folderId
    }
}

export const setDrawings = (drawings: IPanelDrawings[]): SetPanelDrawingsType => {
    return {
        type: "SET_PANEL_DRAWINGS",
        drawings
    }
}

export const setPanelInstancesCount = (panelId: string, instancesCount: number): SetPanelInstancesCountType => {
    return {
        type: "SET_PANEL_INSTANCES_COUNT",
        panelId,
        instancesCount
    }
}

export const setBom = (visible: boolean, items: IBomRow[] = []): SetBomReportType => {
    return {
        type: "SET_BOM_REPORT",
        items,
        visible
    }
}

export const setBomExcelFileUrl = (url: string): SetBomExcelFileUrlType => {
    return {
        type: "SET_FOLDER_BOM_EXCEL_FILE_URL",
        url
    }
}