import { IInspectorCheckPoint, IInspectorNote } from "../entities/panelInspector";
import { Circle, CheckCircle, AlertCircle, ChevronsRight } from "react-feather";

export type CheckPoint = IInspectorCheckPoint & {
    notes: IInspectorNote[];
}

type Props = {
    checkPoint: CheckPoint;
    showCheckPointsNotes: (notes: IInspectorNote[]) => void;
}

export const PanelInspectorCheck = ({ checkPoint, showCheckPointsNotes }: Props) => {
    return <div style={{ display: "flex" }}>
        <div style={{ marginRight: 5 }}>
            {checkPoint.answer === null && <Circle size={14} />}
            {checkPoint.answer === true && <CheckCircle size={14} color="#008000" />}
            {checkPoint.answer === false && <AlertCircle size={14} color="#ff0000" />}
        </div>
        <div style={{ fontWeight: checkPoint.answer === false ? "600" : "initial" }}>{checkPoint.question}</div>
        {checkPoint.notes.length > 0 && <div style={{ marginLeft: 5 }}>
            <ChevronsRight size={14} color="#000080" cursor="pointer" onClick={() => showCheckPointsNotes(checkPoint.notes)} />
        </div>}
    </div>
}