import React, { Component } from 'react';
import Modal from '@weave-design/modal';
import Button from '@weave-design/button';
import Spacer from '@weave-design/spacer';
import merge from "lodash.merge";
import "./confirmDialog.css"

export interface IConfirmDialogProps {
    title: string;
    isOpened: boolean;
    confirmButtonTitle?: string;
    deleteButtonClassName?: string;
    onCancel: () => void;
    onConfirm: () => void;
    confirmDisabled?: boolean;
    windowStyles?: React.CSSProperties;
    promptContainerStyles?: React.CSSProperties;
    children?: any;
}

export class ConfirmDialog extends Component<IConfirmDialogProps> {
    render() {
        const promptContainerStyle: React.CSSProperties = { height: "116px", overflowY: "auto", ...this.props.promptContainerStyles }

        const modalStyles = (styles: any) =>
            merge(styles, {
                modal: {
                    window: { width: "370px", ...this.props.windowStyles }
                }
            });

        return (<Modal
            open={this.props.isOpened}
            title={this.props.title}
            onCloseClick={() => this.props.onCancel()}
            stylesheet={modalStyles}>
            <>
                <div style={promptContainerStyle}>{this.props.children}</div>
                <Spacer spacing="l" />

                <div className="confirmDialog">
                    <div className={this.props.deleteButtonClassName || ""}>
                        <Button
                            size="standard"
                            title={this.props.confirmButtonTitle || "OK"}
                            type="primary"
                            disabled={!!this.props.confirmDisabled}
                            onClick={() => this.props.onConfirm()} />
                    </div>

                    <div style={{ width: '14px' }} />

                    <Button
                        size="standard"
                        title="Cancel"
                        type="secondary"
                        onClick={() => { this.props.onCancel(); }} />
                </div>
            </>
        </Modal>)
    }
}