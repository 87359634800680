import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import Label from '@weave-design/label';
import Input from '@weave-design/input';
import Dropdown from "@weave-design/dropdown"
import Button from '@weave-design/button';
import Spacer from '@weave-design/spacer';
import Typography from "@weave-design/typography";
import IconButton from '@weave-design/icon-button';
import { Folder24 } from '@weave-design/icons';
import { State, getNewModelCreationError, isCustomModelUploadDialogVisible } from '../reducers/mainReducer';
import { showUploadCustomModelSource, uploadCustomInventorModel } from "../actions/panelAutocadSourceModelActions";
import merge from "lodash.merge";
import { unzip, setOptions } from 'unzipit';

import "./models.css"
import { useEffect, useRef, useState } from 'react';

type Props = {
    isVisible: boolean;
    errorMessage: string | null
    showUploadCustomModelSource: (visible: boolean) => void;
    uploadCustomInventorModel: (file: File, assemblyName: string) => void;
}

export const UploadCustomModelForm = ({ isVisible, errorMessage, showUploadCustomModelSource, uploadCustomInventorModel }: Props) => {
    const [sourceFileName, setSourceFileName] = useState("");
    const [inventorAssemblies, setInventorAssemblies] = useState<string[]>([]);
    const [selectedInventorAssembly, setSelectedInventorAssembly] = useState<string>();
    const selectedFile = useRef<File>();

    useEffect(() => {
        selectedFile.current = undefined;
        setSourceFileName("");
        setInventorAssemblies([]);
        setSelectedInventorAssembly(undefined);

        const fileInput = document.getElementById("modelFileInput") as HTMLInputElement | undefined;

        if (fileInput)
            fileInput.value = "";
    }, [isVisible]);

    const listZipAssemblyFiles = async (file: File) => {
        const assemblies: string[] = [];
        setOptions({ numWorkers: 0 });

        const { entries } = await unzip(file);

        Object.entries(entries).forEach(([name]) => {
            if (name.toLowerCase().endsWith('.iam') && !name.toLowerCase().includes('oldversions')) {
                assemblies.push(name);
            }
        });

        return assemblies;
    }

    const onFileSelected = async (data: React.ChangeEvent<HTMLInputElement>) => {
        const files = data.target.files;

        if (!(files && files.length > 0))
            return;

        const file = files[0];

        const fileName = file.name.toLowerCase();

        if (!fileName.endsWith(".zip"))
            return;

        selectedFile.current = file;

        setSourceFileName(fileName);

        const assemblyFiles = await listZipAssemblyFiles(file);

        setInventorAssemblies(assemblyFiles);

        const preselectedAssembly = assemblyFiles.find(x => x.toLowerCase().indexOf("general") >= 0);

        setSelectedInventorAssembly(preselectedAssembly || assemblyFiles[0]);
    }

    return <Modal
        open={isVisible}
        title="New custom panel"
        onCloseClick={() => showUploadCustomModelSource(false)}
        stylesheet={modalStyles}>
        <div id="upload-file-form">
            <div className="fileBrowseContainer">
                <div className="stretch">
                    <Label
                        variant="top"
                        disabled={false} >
                        Model ZIP file:
                    </Label>
                    <Input
                        variant="box"
                        value={sourceFileName}
                        disabled={true}
                    />
                </div>

                <div className="browseButton">
                    <label htmlFor="modelFileInput">
                        <IconButton
                            icon={<Folder24 />}
                            title="Choose panel dwg file"
                            onClick={() => { document.getElementById("modelFileInput")?.click(); }}
                        />
                    </label>
                    <input id="modelFileInput"
                        type="file"
                        accept=".zip"
                        onChange={(e) => onFileSelected(e)}
                    />
                </div>
            </div>
            <Spacer spacing="l" />
            <Label
                variant="top"
                disabled={false} >
                Main assembly (3D model):
            </Label>
            <Dropdown
                options={inventorAssemblies}
                value={selectedInventorAssembly || ""}
                onChange={(newValue: string) => setSelectedInventorAssembly(newValue)}
                stylesheet={assemblySelectorStyles} />

            {errorMessage !== null && <Typography children={errorMessage} className="creation-failed-message" />}

            <div className="buttonsContainer" style={{ position: "absolute", bottom: 16, right: 24 }}>
                <Button
                    size="standard"
                    title="Create"
                    type="primary"
                    onClick={() => uploadCustomInventorModel(selectedFile.current!, selectedInventorAssembly!)}
                    disabled={sourceFileName === "" || selectedInventorAssembly === undefined || selectedInventorAssembly === ""} />
                <div style={{ width: '14px' }} />
                <Button
                    id="cancel_button"
                    size="standard"
                    title="Cancel"
                    type="secondary"
                    onClick={() => showUploadCustomModelSource(false)} />
            </div>
        </div>

    </Modal>
}

export default connect(function (store: State) {
    return {
        isVisible: isCustomModelUploadDialogVisible(store),
        errorMessage: getNewModelCreationError(store)
    }
}, { showUploadCustomModelSource, uploadCustomInventorModel })(UploadCustomModelForm);

const modalStyles = (styles: any) =>
    merge(styles, {
        modal: {
            window: { // by design
                width: "570px",
                height: "427px"
            },
            bodyContent: {
                overflow: "hidden" // no scrollbar
            }
        }
    });

const assemblySelectorStyles = (styles: any) => {
    return {
        ...styles,
        menu: {
            ...styles.menu,
            maxHeight: "180px"
        }
    }
}