import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { createPanelsInstances, showPanelsInstancesCreationDialog, showPanelsInstancesCreationError } from "../actions/panelInstancesActions";
import { getInstancesCreationError, isInstancesDialogCreationVisible, State } from "../reducers/mainReducer";
import Modal from '@weave-design/modal';
import Label from '@weave-design/label';
import Input from '@weave-design/input';
import Button from '@weave-design/button';
import Typography from "@weave-design/typography";
import IconButton from '@weave-design/icon-button';
import Spacer from '@weave-design/spacer';
import { Folder24 } from '@weave-design/icons';
import merge from "lodash.merge";
import "./models.css"

type Props = {
    isVisible: boolean;
    errorMessage: string | null;

    showPanelsInstancesCreationDialog: (visible: boolean) => void;
    showPanelsInstancesCreationError: (message: string | null) => void;
    createPanelsInstances: (file: File) => void;
}

export const CreatePanelsInstancesDialog = ({ isVisible, errorMessage, showPanelsInstancesCreationDialog, 
    showPanelsInstancesCreationError, createPanelsInstances }: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState("");
    const fileInputElement = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isVisible) {
            setFile(null);
            setFileName("");
        }
    }, [isVisible]);

    const modalStyles = useRef((styles: any) =>
        merge(styles, {
            modal: {
                window: { // by design
                    width: "400px",
                    height: "230px",
                    top: "calc(50% - 115px)"
                },
                bodyContent: {
                    overflow: "hidden" // no scrollbar
                }
            }
        }));

    if (!isVisible)
        return (null);

    const close = () => {
        setFile(null);
        setFileName("");
        showPanelsInstancesCreationDialog(false);
    }

    return <>
        <Modal
            open={isVisible}
            title="Panel instances"
            onCloseClick={() => close()}
            stylesheet={modalStyles.current}>
            <div id="upload-file-form">
                <div className="fileBrowseContainer">
                    <div className="stretch">
                        <Label
                            variant="top"
                            disabled={false} >
                            Excel input file:
                        </Label>
                        <Input
                            variant="box"
                            value={fileName}
                            disabled={true}
                        />
                    </div>
                    <div className="browseButton">
                        <label htmlFor="modelFileInput">
                            <IconButton
                                icon={<Folder24 />}
                                title="Choose Excel file"
                                onClick={() => {
                                    showPanelsInstancesCreationError(null);
                                    fileInputElement.current?.click();
                                }}
                            />
                        </label>
                        <input id="modelFileInput"
                            ref={fileInputElement}
                            type="file"
                            accept=".xlsx"
                            onChange={(e) => {
                                if (!e.target.files || e.target.files.length === 0)
                                    return;

                                const fileToUpload = e.target.files[0];

                                const uploadFileName = fileToUpload.name.toLowerCase();

                                if (!uploadFileName.endsWith(".xlsx"))
                                    return;

                                setFile(fileToUpload);
                                setFileName(uploadFileName);
                            }}
                        />
                    </div>
                </div>

                {errorMessage !== null && <Typography children={errorMessage} className="creation-failed-message" />}
                {errorMessage === null && <Spacer spacing="l" />}

                <div className="buttonsContainer">
                    <Button
                        size="standard"
                        title="Create"
                        type="primary"
                        onClick={() => {
                            if (!file)
                                return;

                            createPanelsInstances(file);
                        }}
                        disabled={file === null} />
                    <div style={{ width: '14px' }} />
                    <Button
                        id="cancel_button"
                        size="standard"
                        title="Cancel"
                        type="secondary"
                        onClick={() => close()} />
                </div>
            </div>
        </Modal>
    </>
}

export default connect(function (store: State) {
    return {
        isVisible: isInstancesDialogCreationVisible(store),
        errorMessage: getInstancesCreationError(store)
    }
}, { showPanelsInstancesCreationDialog, showPanelsInstancesCreationError, createPanelsInstances })(CreatePanelsInstancesDialog);