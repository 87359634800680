import { Component } from 'react';
import { connect } from 'react-redux';
import Surface from '@hig/surface';
import Toolbar from "./components/toolbar";
import LoginForm from "./components/loginForm";
import Panels from "./components/panels";
import PanelViewer from "./components/panelViewer";
import UploadForm from "./components/uploadForm";
import UploadCustomModelForm from './components/uploadCustomModelForm';
import ProcessingPane from "./components/processingPane";
import ProcessingFailure from "./components/processingFailure";
import { detectToken } from "./actions/profileActions";
import { isLoggedIn, State } from "./reducers/mainReducer";
import { ApplicationUserState } from './entities/user';
import FoldersBreadCrumb from './components/foldersBreadCrumb';
import FolderEditor from './components/folderEditor';
import BomDownload from './components/bomDownload';
import Bom from './components/bom';
import FolderReportDialog from './components/folderReportDialog';
import CreatePanelsInstancesDialog from './components/createPanelsInstancesDialog';
import './App.css'

interface IComponentProps {
  isLoggedIn: ApplicationUserState;

  detectToken: () => void;
}

export class App extends Component<IComponentProps> {
  componentDidMount() {
    this.props.detectToken()
  }

  render() {
    return (
      <Surface className="fullheight" id="main" level={200}>
        <Toolbar />
        {this.props.isLoggedIn === "not-logged" && <LoginForm />}
        {this.props.isLoggedIn === "logged" && <>
          <PanelViewer />
          <FoldersBreadCrumb />
          <Panels />
          <Bom />
          <UploadForm />
          <UploadCustomModelForm />
          <CreatePanelsInstancesDialog />
          <FolderEditor />
          <ProcessingPane />
          <ProcessingFailure />
          <BomDownload />
          <FolderReportDialog />
        </>}
      </Surface>
    );
  }
}

export default connect(function (store: State) {
  return {
    isLoggedIn: isLoggedIn(store)
  }
}, { detectToken })(App);