export enum PanelType {
    Panel = 1,

    Corner = 2,

    CustomOpaquePanel = 3,

    CustomWindowedPanel = 4,

    CustomCornerPanel = 5
}

export enum PanelSystemType {
    Dwall = 0,

    Snow = 1
}

export interface IPanel {
    id: string;
    createdAt: Date;
    panelType: PanelType;
    panelWidth: number;
    panelHeight: number;
    panelThickness: number;
    previewImageUrl: string;
    modelUrl: string;
    downloadUrl: string;
    cornerPanelLeftWingWidth: number;
    cornerPanelRightWingWidth: number;
    cornerPanelAngle: number;
    productionLod: boolean;
    instancesCount: number;
    windows: any[]; // TODO: define windows
    typeName?: string;
    instanceName?: string;
    isInstancePanel: boolean;
    panelSystemType: PanelSystemType;
    engineBuiltWith: number;
}

export interface IPanelDrawings {
    id: string;
    name: string;
    modelUrl: string;
}

export const isCustomPanelType = (panelType: PanelType) => panelType !== PanelType.Panel && panelType !== PanelType.Corner;

export const getPanelLODTitle = (panel: IPanel) => {
    if (isCustomPanelType(panel.panelType))
        return "Custom";

    return panel.productionLod ? "Production" : "Architecture";
}

export const getPanelTypeTitle = (panel: IPanel) => {
    if (isCustomPanelType(panel.panelType))
        return "Custom";

    return panel.panelSystemType === PanelSystemType.Dwall ? "DWall" : "Snow";
}