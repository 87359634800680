import { IFolder } from "../entities/folder"
import Typography from "@weave-design/typography";
import Button from "@weave-design/button";
import "bootstrap/dist/css/bootstrap.min.css"
import "./panels.css";

interface IComponentProps {
    folder: IFolder;
    hideEditButtons: boolean;
    select: () => void;
    delete: () => void;
    edit: () => void;
}

export const FolderCard = (props: IComponentProps) => {
    return (<div className="col-lg-3 col-md-4 col-sm-6 col-12 pb-3">
        <div className="card">
            <img className="card-img-top" src="/folder.svg" alt="" style={{ cursor: "pointer" }} onClick={() => props.select()} />
            <div className="card-body">
                <Typography className="card-title" style={{ whiteSpace: "normal" }}><strong>{props.folder.name}</strong></Typography>
                <Typography className="card-text">Created at: {new Date(props.folder.createdAt).toLocaleString()}</Typography>
            </div>
            <div className="card-footer">
                <Button type="primary" size="standard" title="Open" onClick={() => props.select()} />
                {!props.hideEditButtons && <>
                    <div style={{ width: "10px", display: "inline-block" }}></div>
                    <Button type="secondary" size="standard" title="Rename" onClick={() => props.edit()} />
                    <div style={{ width: "10px", display: "inline-block" }}></div>
                    <button className="btn btn-sm btn-outline-secondary" style={{ height: "36px", marginBottom: "1px" }} onClick={() => props.delete()} title="Delete folder">
                        <img src="/trash.svg" alt="" />
                    </button>
                </>}
            </div>
        </div>
    </div>)
}