import { useRef } from "react";
import { IPanel } from "../entities/panel"
import "./panels.css"

type Props = {
    panel: IPanel;
}

export const PanelQRCode = ({ panel }: Props) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    const downloadImage = () => {
        const image = imageRef.current;

        if (!image)
            return;

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        if (!context)
            return;

        const targetSize = 512

        canvas.width = targetSize;
        canvas.height = targetSize;

        context.drawImage(image, 0, 0, targetSize, targetSize);

        canvas.toBlob(blob => {
            const url = window.URL.createObjectURL(blob!);

            const downloadLink = downloadLinkRef.current!;

            downloadLink.href = url;

            downloadLink.click();
            
            window.URL.revokeObjectURL(url);
        });
    }

    return <div className="panel-qr-code-container">
        <img src={`/panels/${panel.id}/qr`} alt="QR code" ref={imageRef} style={{ cursor: "pointer" }} onClick={downloadImage} />
        <a download={`${panel.typeName || panel.id}.png`} ref={downloadLinkRef} style={{display: "none"}}/>
    </div>
}