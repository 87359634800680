import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import Typography from "@weave-design/typography";
import Spacer from '@weave-design/spacer';
import Label from '@weave-design/label';
import Input from '@weave-design/input';
import Button from "@weave-design/button";
import { editLogin, editPassword, tryLogin } from '../actions/loginActions'
import { canSignIn, getUserName, getPassword, getLoginResults, State } from '../reducers/mainReducer';
import './loginForm.css';

interface IComponentProps {
    canSignIn: boolean;
    userName: string;
    password: string;
    loginResults: { isSuccess: boolean, message: string };

    editLogin: (newLogin: string) => void;
    editPassword: (newPassword: string) => void;
    tryLogin: () => void;
}

export class LoginForm extends Component<IComponentProps> {
    render() {
        return (<div className="loginMainContainer">
            <React.Fragment><Modal open title="Login" className="loginform">
                <Typography>Enter your credentials:</Typography>
                <Spacer />
                <Label>Login:</Label>
                <Input variant="box" value={this.props.userName} onChange={(data: React.ChangeEvent<HTMLInputElement>) => this.props.editLogin(data.target.value)}></Input>
                <Label>Password:</Label>
                {/*<Input variant="box" className="passwordbox" value={this.props.password} onChange={(data: React.ChangeEvent<HTMLInputElement>) => this.props.editPassword(data.target.value)}></Input>*/}
                <div className="passwordbox">
                    <input type="password" value={this.props.password} onChange={data => this.props.editPassword(data.target.value)} />
                </div>
                {!this.props.loginResults.isSuccess && <Typography children={this.props.loginResults.message} className="invalid-login" />}
                <Spacer spacing="l" />
                <div className="buttonsContainer">
                    <Button size="standard"
                        title="Sign in"
                        type="primary"
                        disabled={!this.props.canSignIn}
                        onClick={() => this.props.tryLogin()}
                    />
                </div>
            </Modal></React.Fragment>
        </div>)
    }
}

export default connect(function (store: State) {
    return {
        canSignIn: canSignIn(store),
        userName: getUserName(store),
        password: getPassword(store),
        loginResults: getLoginResults(store)
    }
}, { editLogin, editPassword, tryLogin })(LoginForm);