import { useEffect, useState } from "react"
import { ForgeView } from "./components/forgeView";
import { LoadingSpinner } from "./components/loadingSpinner";
import { getQueryStringParams } from "./components/shared";
import { IPanel } from "./entities/panel";
import repo from "./Repository";

export const PanelInspectorIntegrationApp = () => {
    const [panel, setPanel] = useState<IPanel | null>(null);

    const loadPanel = async (id: string) => {
        const response = await repo.findPanel(id);

        if (!response.isSuccess) {
            window.location.href = window.location.origin;
            return;
        }

        setPanel(response.item);
    }

    useEffect(() => {
        const queryParameters = getQueryStringParams() as { panel?: string }

        const panelId = queryParameters.panel;

        if (!panelId) {
            window.location.href = window.location.origin;

            return;
        }

        loadPanel(panelId);

    }, []);

    return <div style={containerStyle}>
        {!panel && <LoadingSpinner />}
        {panel && <ForgeView
            id={panel.id}
            modelUrl={panel.modelUrl}
            isPdfDocument={false}
            panelType={panel.panelType}
            headless={true} />}
    </div>
}

const containerStyle: React.CSSProperties = {
    width: "100%",
    height: "100vh"
}