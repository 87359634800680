import { IPanel, PanelType } from "../entities/panel";
import { round } from "./shared";

type Props = {
    panel: IPanel;
}

export const PanelTitle = ({ panel }: Props) => {
    const heigth = round(panel.panelHeight, 2);

    switch (panel.panelType) {
        case PanelType.Panel: {
            const width = round(panel.panelWidth, 2);

            switch (panel.windows.length) {
                case 0:
                    return (<strong>{width}" x {heigth}" without windows</strong>);

                case 1:
                    return (<strong>{width}" x {heigth}" with 1 window</strong>);

                default:
                    return (<strong>{width}" x {heigth}" with {panel.windows.length} windows</strong>);
            }
        }

        case PanelType.Corner: {
            const leftWingWidth = round(panel.cornerPanelLeftWingWidth);
            const rightWingWidth = round(panel.cornerPanelRightWingWidth);

            return (<strong>({leftWingWidth}" | {rightWingWidth}") x {heigth}"</strong>);
        }

        case PanelType.CustomOpaquePanel:
            return (<strong>Custom opaque panel</strong>);

        case PanelType.CustomWindowedPanel:
            return (<strong>Custom windowed panel</strong>);

        case PanelType.CustomCornerPanel:
            return (<strong>Custom corner panel</strong>);

        default:
            return (<strong>Unidentified entity</strong>);
    }

}