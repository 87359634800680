import { useEffect, useRef, useState } from 'react';
import { ConfirmDialog } from './confirmDialog';
import { viewerCss, viewerJs } from './shared';
import { StudioIntegrationExtension } from './studioIntegrationExtension';
import { ProcessingPane } from "./processingPane";

interface IComponentProps {
    modelUrl: string;
    isPdfDocument: boolean;
    panelType: number;
    id: string;
    headless?: boolean;
}

const integrationExtensionName = "Dextall.StudioIntegrationExtension";

export const ForgeView = (props: IComponentProps) => {
    const [confirmPushToDextallStudioDialogVisible, setConfirmPushToDextallStudioDialogVisible] = useState(false);
    const [processingPaneVisible, setProcessingPaneVisible] = useState(false);
    const viewer = useRef<Autodesk.Viewing.GuiViewer3D | Autodesk.Viewing.Viewer3D>();

    const viewerDiv = useRef(null);

    useEffect(() => {
        if (!viewer.current)
            return;

        if (props.isPdfDocument) {
            const forgeViewer = createViewer();

            viewer.current = forgeViewer;

            forgeViewer.start();

            forgeViewer.loadExtension("Autodesk.PDF").then(() => forgeViewer.loadModel(props.modelUrl, { loadOptions: { page: 1 }, skipPropertyDb: true }));
        }
        else
            initializeViewer();

        return () => viewer.current?.finish();
    }, [props.modelUrl]);

    useEffect(() => {
        const scriptId = "forge-viewer-script";

        if (document.getElementById(scriptId)) {
            initializeViewer();

            return;
        }

        const script = document.createElement("script");

        script.id = scriptId;
        script.onload = async () => await initializeViewer();
        script.src = viewerJs;

        document.head.appendChild(script);

        const css = document.createElement("link");

        css.rel = "stylesheet";
        css.type = "text/css";
        css.href = viewerCss;

        document.head.appendChild(css);
    }, []);

    const initializeViewer = async () => {
        await import("./studioIntegrationExtension");

        const forgeViewer = createViewer();

        viewer.current = forgeViewer;

        const options: Autodesk.Viewing.InitializerOptions = { env: "Local" };

        Autodesk.Viewing.Initializer(options, () => handleViewerInit(forgeViewer));
    }

    const handleViewerInit = (forgeViewer: Autodesk.Viewing.GuiViewer3D | Autodesk.Viewing.Viewer3D) => {
        forgeViewer.start();

        forgeViewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, () => {
            forgeViewer.navigation.toOrthographic();
            forgeViewer.setLightPreset(18); //"boardwalk"
        }, { once: true });

        Autodesk.Viewing.Document.load(props.modelUrl, doc => handleModelLoaded(forgeViewer, doc), () => { });
    }

    const handleModelLoaded = async (forgeViewer: Autodesk.Viewing.GuiViewer3D | Autodesk.Viewing.Viewer3D, viewerDocument: Autodesk.Viewing.Document) => {
        const geometry = viewerDocument.getRoot().getDefaultGeometry();
        await forgeViewer.loadDocumentNode(viewerDocument, geometry);
        await forgeViewer.waitForLoadDone();

        if ("autocam" in forgeViewer)
            forgeViewer.autocam.setCurrentViewAsHome(true);
    }

    const createViewer = (): Autodesk.Viewing.GuiViewer3D | Autodesk.Viewing.Viewer3D => {
        const container: HTMLElement = viewerDiv.current!;

        const extensions = props.headless ? [] : [integrationExtensionName, "Autodesk.DocumentBrowser"];

        const config: Autodesk.Viewing.Viewer3DConfig = {
            theme: "light-theme",
            extensions,
            disabledExtensions: {
                bimwalk: true
            },
            panelType: props.panelType,
            panelId: props.id,
            showConfirmPushToDextallStudioDialog: () => setConfirmPushToDextallStudioDialogVisible(true),
            showProcessingPane: (visible: boolean) => setProcessingPaneVisible(visible)
        };

        return props.headless
            ? new Autodesk.Viewing.Viewer3D(container, config)
            : new Autodesk.Viewing.GuiViewer3D(container, config);
    }

    const pushToDextallStudioCatalog = async () => {
        setConfirmPushToDextallStudioDialogVisible(false);

        if (!viewer.current)
            return;

        const integrationExtension = viewer.current.getExtension(integrationExtensionName) as StudioIntegrationExtension;

        await integrationExtension.pushPanelToDextallStudio();
    }

    return (<>
        <div className="viewer" id="ForgeViewer" ref={viewerDiv}></div>
        <ConfirmDialog
            isOpened={confirmPushToDextallStudioDialogVisible}
            title="Dextall Studio integration"
            confirmButtonTitle="Yes"
            deleteButtonClassName="red"
            onCancel={() => setConfirmPushToDextallStudioDialogVisible(false)}
            onConfirm={pushToDextallStudioCatalog}
            windowStyles={{ width: "500px", height: "220px" }}
            promptContainerStyles={{ height: "50px" }}>
            Do you want to offer to include this panel into Dextall Studio panels catalog?
        </ConfirmDialog>
        <ProcessingPane isVisible={processingPaneVisible} message={"Pushing to the catalog"} />
    </>)
}