import { useCallback, useState } from "react";
import Modal from "@weave-design/modal";
import merge from "lodash.merge";

type Props = {
    url: string;
    size: number;
}
export const PanelInspectorNoteAttachementCard = ({ url, size }: Props) => {
    const [previewOpened, setPreviewOpened] = useState(false);

    const modalStyles = useCallback((styles: any) => {
        return merge(styles, {
            modal: {
                window: { // by design
                    width: "100%",
                    height: "calc(100vh)",
                    top: 5
                },
                bodyContent: {
                    ...styles.modal.bodyContent,
                    padding: "0px",
                    marginLeft: "auto",
                    marginRight: "auto"
                }
            }
        });
    }, []);

    return <div style={{ width: size, height: size, breakInside: "avoid", marginLeft: "auto", marginRight: "auto", marginBottom: "5px", cursor: "pointer" }}>
        <img src={url} style={{ width: size, height: size }} alt="" onClick={() => setPreviewOpened(true)} />
        {previewOpened && <div style={{position: "absolute", top: 0, left: 0}}>
            <Modal
                open={true}
                onCloseClick={() => setPreviewOpened(false)}
                title="Photo"
                stylesheet={modalStyles}>
                <div style={{ marginLeft: "auto", marginRight: "auto", padding: 8 }}>
                    <img src={url} alt="" style={{ maxWidth: "100%", maxHeight: "calc(100vh - 130px)" }} />
                </div>
            </Modal>
        </div>}
    </div>
}