import { ProcessingActionType } from '../actions/processingActions'

export type State = {
    processingPaneVisible: boolean;
    processingCurrentMessage: string | null;
    processingErrorMessage: string | null;
    processingErrorTitle: string;
}

const defaultErrorTitle = "Panel generation failed";

export const initialState: State = {
    processingPaneVisible: false,
    processingCurrentMessage: null,
    processingErrorMessage: null,
    processingErrorTitle: defaultErrorTitle
}

export const isProcessingPaneVisible = function (state: State): boolean {
    return state.processingPaneVisible;
}

export const getProcessingMessage = function (state: State): string | null {
    return state.processingCurrentMessage;
}

export const isProcessingFailed = function (state: State): boolean {
    return state.processingErrorMessage !== null;
}

export const getProcessingFailure = function (state: State): string | null {
    return state.processingErrorMessage;
}

export const getProcessingFailureTitle = function (state: State): string {
    return state.processingErrorTitle;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: ProcessingActionType): State {
    switch (action.type) {
        case "PROCESSING_PANE_SHOW": {
            return { ...state, processingPaneVisible: action.visible, processingCurrentMessage: null };
        }

        case "PROCESSING_PANE_PROGRESS": {
            return { ...state, processingCurrentMessage: action.message };
        }

        case "SET_PROCESSING_FAILURE": {
            return { ...state, processingErrorMessage: action.message, processingErrorTitle: action.title || defaultErrorTitle };
        }

        case "CLOSE_PROCESSING_FAILURE": {
            return { ...state, processingErrorMessage: null }
        }

        default:
            return state;
    }
}