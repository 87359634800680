type ShowProcessingPaneType = {
    type: "PROCESSING_PANE_SHOW";
    visible: boolean;
}

type ReportProcessingProgressType = {
    type: "PROCESSING_PANE_PROGRESS";
    message: string;
}

type ShowProcessingErrorType = {
    type: "SET_PROCESSING_FAILURE";
    message: string;
    title?: string;
}

type CloseProcessingErrorDialogType = {
    type: "CLOSE_PROCESSING_FAILURE"
}

export type ProcessingActionType = ShowProcessingPaneType | ReportProcessingProgressType | ShowProcessingErrorType | CloseProcessingErrorDialogType;

export const showProcessingPane = (visible: boolean): ShowProcessingPaneType => {
    return {
        type: "PROCESSING_PANE_SHOW",
        visible
    }
}

export const reportProcessingProgress = (message: string): ReportProcessingProgressType => {
    return {
        type: "PROCESSING_PANE_PROGRESS",
        message
    }
}

export const showProcessingError = (message: string, title?: string): ShowProcessingErrorType => {
    return {
        type: "SET_PROCESSING_FAILURE",
        message,
        title
    }
}

export const closeProcessingErrorDialog = (): CloseProcessingErrorDialogType => {
    return {
        type: "CLOSE_PROCESSING_FAILURE"
    }
}