import { useState } from 'react';
import { connect } from 'react-redux';
import Modal from "@weave-design/modal";
import { ForgeView } from "./forgeView";
import { selectPanel, updatePanelInstancesCount } from "../actions/panelsActions";
import { getSelectedPanel, getPanelDrawings, State, getProfile } from "../reducers/mainReducer";
import merge from "lodash.merge";
import { IPanel, IPanelDrawings } from '../entities/panel';
import { PanelProperties } from './panelProperties';
import { PanelContentDownload } from './panelContentDownload';
import { useEffect } from 'react';
import { PanelViewablesSelector } from './panelViewablesSelector';
import { PanelViewportSwitcher, Viewport } from './panelViewportSwitcher';
import { PanelInspectorNotes } from './panelInspectorNotes';
import "./panelViewer.css"

interface IComponentProps {
    selectedPanel: IPanel | null;
    drawings: IPanelDrawings[];
    isRobot: boolean;

    selectPanel: (panelId?: string) => void;
    updatePanelInstancesCount: (panelId: string, instancesCount: number) => void;
}

interface IViewerModel {
    title: string;
    modelUrl: string;
    isPdfDocument: boolean;
}


export const PanelViewer = (props: IComponentProps) => {
    const [viewerModels, setViewerModels] = useState<IViewerModel[]>([]);
    const [selectedViewable, setSelectedViewable] = useState(-1);
    const [selectedViewport, setSelectedViewport] = useState<Viewport>("Model");

    useEffect(() => {
        if (!props.selectedPanel) {
            setViewerModels([]);
            setSelectedViewable(-1);

            return;
        }

        const models: IViewerModel[] = [];

        models.push({
            title: "3D",
            modelUrl: props.selectedPanel.modelUrl,
            isPdfDocument: false
        });

        for (const drawing of props.drawings)
            models.push({
                title: drawing.name,
                modelUrl: drawing.modelUrl.substring(1),
                isPdfDocument: true
            });

        setViewerModels(models);
        setSelectedViewable(0);
    }, [props.selectedPanel, props.drawings])


    const modalStyles = (styles: any) =>
        merge(styles, {
            modal: {
                window: { // by design
                    width: "100%",
                    height: "calc(100% - 50px)",
                    top: "50px"
                },
                bodyContent: {
                    ...styles.modal.bodyContent,
                    padding: "0px"
                }
            }
        });

    if (props.selectedPanel === null)
        return (null);

    return (<Modal
        open={true}
        onCloseClick={() => props.selectPanel(undefined)}
        stylesheet={modalStyles}
        title={<PanelViewportSwitcher title="Panel" viewport={selectedViewport} setViewport={setSelectedViewport} />}>
        {selectedViewport === "Model" && <div className='fullheight inRow'>
            <div className="parametersContainer fullheight">
                {props.selectedPanel && <>
                    <PanelProperties panel={props.selectedPanel} />
                    <PanelContentDownload panel={props.selectedPanel} />
                    <PanelViewablesSelector models={viewerModels} selectedViewable={selectedViewable} setSelectedViewable={(i) => setSelectedViewable(i) }/>
                </>}
            </div>
            <div className="modelContainer fullheight">
                {viewerModels[selectedViewable] && <ForgeView {...viewerModels[selectedViewable]} panelType={props.selectedPanel.panelType} id={props.selectedPanel.id} />}
            </div>
        </div>}
        {selectedViewport === "Inspector" && props.selectedPanel !== null && <PanelInspectorNotes panel={props.selectedPanel}/>}
    </Modal>);
}

export default connect(function (store: State) {
    return {
        selectedPanel: getSelectedPanel(store),
        drawings: getPanelDrawings(store),
        isRobot: !!getProfile(store)?.isRobot
    };
}, { selectPanel, updatePanelInstancesCount })(PanelViewer);