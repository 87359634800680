import { useEffect, useState } from "react";
import { IPanel } from "../entities/panel";
import { IInspectorCheckPoint, IInspectorNote } from "../entities/panelInspector";
import { LoadingSpinner } from "./loadingSpinner";
import { PanelQRCode } from "./panelQRCode";
import { PanelTitle } from "./panelTitle";
import repo from "../Repository";
import "./panelViewer.css"
import { PanelInspectorCheckList } from "./panelInspectorCheckList";
import { PanelInspectorAdditionalNotes } from "./panelInspectorAdditionalNotes";

type Props = {
    panel: IPanel;
}

export const PanelInspectorNotes = ({ panel }: Props) => {
    const [checkPoints, setCheckPoints] = useState<IInspectorCheckPoint[] | null>(null);
    const [notes, setNotes] = useState<IInspectorNote[] | null>(null);
    const [inspectorNotesToShow, setInspectorNotesToShow] = useState<IInspectorNote[]>([]);

    const load = async () => {
        const [checkPointsResponse, notesResponse] = await Promise.all([repo.findPanelInspectorCheckPoints(panel.id), repo.findPanelInspectorNotes(panel.id)]);

        if (!checkPointsResponse.isSuccess) {
            alert(checkPointsResponse.message);
            return;
        }

        if (!notesResponse.isSuccess) {
            alert(notesResponse.message);

            return;
        }

        setCheckPoints(checkPointsResponse.items!);
        setNotes(notesResponse.items!);
    }

    useEffect(() => {
        setCheckPoints(null);
        setNotes(null);
        setInspectorNotesToShow([]);

        load();
    }, [panel]);

    return <div className='fullheight inRow'>
        <div className="parametersContainer fullheight">
            <PanelTitle panel={panel} />
            <PanelQRCode panel={panel} />
        </div>
        <div className="fullheight" style={{ padding: 8, overflowY: "auto", width: inspectorNotesToShow.length === 0 ? "calc(100% - 300px)" : "calc(60% - 300px)" }}>
            {(checkPoints === null || notes === null) && <LoadingSpinner />}
            {checkPoints !== null && notes !== null && <PanelInspectorCheckList
                checkPoints={checkPoints}
                inspectorNotes={notes}
                showCheckPointsNotes={setInspectorNotesToShow} />}
        </div>
        {inspectorNotesToShow.length > 0 && <div className="fullheight" style={{ padding: 8, overflowY: "auto", width: "40%" }}>
            <PanelInspectorAdditionalNotes notes={inspectorNotesToShow} close={() => setInspectorNotesToShow([])} />
        </div>}
    </div>

}