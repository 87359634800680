import { connect } from 'react-redux';
import { IBomRow } from "../entities/bomRow";
import { setBom, createExcelBomReport } from "../actions/panelsActions";
import { getBom, isBomVisible, getCurrentFolder, State } from '../reducers/mainReducer';
import Modal from "@weave-design/modal";
import Button from "@weave-design/button"
import { Download24 } from "@weave-design/icons";
import merge from "lodash.merge";
import { BomRow } from './bomRow';

interface IComponentProps {
    visible: boolean;
    items: IBomRow[];
    folderId: string | null;
    setBom: (visible: boolean) => void;
    createExcelBomReport: (folderId: string) => void;
}

const Bom = (props: IComponentProps) => {
    if (!props.visible)
        return (null);

    const modalStyles = (styles: any) =>
        merge(styles, {
            modal: {
                window: { // by design
                    width: "100%",
                    height: "calc(100% - 50px)",
                    top: "50px"
                },
                bodyContent: {
                    ...styles.modal.bodyContent,
                    padding: "0px"
                }
            }
        });

    const items = props.items.map((x, i) => (<BomRow item={x} itemNumber={i + 1} key={`bom-item${i}`} />));

    return (<Modal
        open={true}
        onCloseClick={() => props.setBom(false)}
        stylesheet={modalStyles}
        title="Folder BOM">
        <div className="fullheight">
            <div className="fullheight" style={{ height: "calc(100vh - 167px)", overflowY: "scroll" }}>
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Part Number</th>
                            <th>QTY</th>
                            <th>Depth</th>
                            <th>Height</th>
                            <th>Width</th>
                            <th>Length</th>
                            <th>Flatpattern Length</th>
                            <th>Flatpattern Width</th>
                            <th>Flatpattern Area</th>
                            <th>Drawing Number</th>
                            <th>Material Group</th>
                            <th>Material</th>
                            <th>Color</th>
                            <th>Gloss</th>
                            <th>Thickness</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px", marginRight: "10px", marginLeft: "auto" }}>
                <Button title="Download" type="primary" icon={<Download24 />} onClick={() => props.createExcelBomReport(props.folderId!)} />
            </div>
        </div>
    </Modal>)
}

export default connect(function (store: State) {
    return {
        visible: isBomVisible(store),
        items: getBom(store),
        folderId: getCurrentFolder(store)?.id || null
    };
}, { setBom, createExcelBomReport })(Bom);