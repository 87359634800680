import Button from "@weave-design/button"
import { connect } from 'react-redux';
import { generatePanelStickyNotes } from "../actions/panelsActions";
import { State, getCurrentFolder, getFolderStack } from '../reducers/mainReducer';
import { IFolder } from '../entities/folder';

type Props = {
    folders: IFolder[];
    folder: IFolder | null;
    generatePanelStickyNotes: (folderId: string) => void;
}

export const PanelsStickyNotesGenerator = ({ folders, folder, generatePanelStickyNotes }: Props) => {
    const isTopLevelFolder = folders.length === 1;

    if (!(isTopLevelFolder && folder))
        return (null);

    return <Button
        title="QR-Codes"
        icon={<img src="/qr.svg" style={{ width: 24, height: 24 }} />}
        type="secondary" style={{ marginRight: "10px" }}
        onClick={() => generatePanelStickyNotes(folder.id)} />
}

export default connect(function (store: State) {
    return {
        folders: getFolderStack(store),
        folder: getCurrentFolder(store)
    };
}, {
    generatePanelStickyNotes
})(PanelsStickyNotesGenerator);