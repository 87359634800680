import { IInspectorNote } from "../entities/panelInspector";
import { X } from "react-feather";
import { PanelInspectorAdditionalNote } from "./panelInspectorAdditionalNote";

type Props = {
    notes: IInspectorNote[];
    close: () => void;
}

export const PanelInspectorAdditionalNotes = ({ notes, close }: Props) => {
    if (notes.length === 0)
        return null;

    const notesElements = notes.map(x => <li key={`inspector-additional-note-${x.id}`}><PanelInspectorAdditionalNote additionalNote={x} /></li>)

    return <>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <div>
                <h4>{notes[0].question}</h4>
            </div>
            <div>
                <X onClick={close} cursor="pointer" />
            </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <ol>
                {notesElements}
            </ol>
        </div>
    </>
}