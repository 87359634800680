import React from "react";
import { IFolder } from "../entities/folder"

interface IComponentProps {
    folder: IFolder | null;
    isCurrent: boolean;
    title?: string;
    select: (folder: IFolder | null) => void;
}

export const FolderBreadCrumb = (props: IComponentProps) => {
    return (<>
        <div onClick={() => props.select(props.folder)} style={{ fontWeight: props.isCurrent ? "bold" : "normal" }}>{props.folder?.name || props.title || "Top"}</div>
        {!props.isCurrent && <div> &gt; </div>}
    </>)
}