import { PanelAutocadSourceModelActionType } from '../actions/panelAutocadSourceModelActions'

export type ModelSource = {
    productionLod: boolean;
}

export type State = {
    showUploadDialog: boolean;
    showUploadCustomModelDialog: boolean;
    modelSource: ModelSource;
    cornerModel: boolean;
    newModelCreationError: string | null;
}

export const defaultModelSource: ModelSource = {
    productionLod: true
}

export const initialState: State = {
    showUploadDialog: false,
    showUploadCustomModelDialog: false,
    modelSource: defaultModelSource,
    cornerModel: false,
    newModelCreationError: null
}

export const isModelUploadDialogVisible = function (state: State): boolean {
    return state.showUploadDialog;
}

export const isCustomModelUploadDialogVisible = function(state: State): boolean {
    return state.showUploadCustomModelDialog;
}

export const getModelSource = function (state: State): ModelSource {
    return state.modelSource;
}

export const getNewModelCreationError = function (state: State): string | null {
    return state.newModelCreationError;
}

export const isCornerModelUpload = function (state: State): boolean {
    return state.cornerModel;
}

export default function (state: State = initialState, action: PanelAutocadSourceModelActionType): State {
    switch (action.type) {
        case "SHOW_UPLOAD_MODEL_SOURCE": {
            return { ...state, showUploadDialog: action.visible, modelSource: defaultModelSource, newModelCreationError: null, cornerModel: action.cornerModel }
        }

        case "SHOW_UPLOAD_CUSTOM_MODEL_SOURCE": {
            return { ...state, showUploadCustomModelDialog: action.visible, newModelCreationError: null }
        }

        case "MODEL_SOURCE_FILE_SELECTED": {
            const newState = { ...state };
            const modelSource = { ...newState.modelSource, file: action.file };

            return { ...newState, modelSource: modelSource, newModelCreationError: null };
        }

        case "SET_PRODUCTION_LOD": {
            const newState = { ...state };

            const modelSource = { ...newState.modelSource, productionLod: action.value };

            return { ...newState, modelSource: modelSource, newModelCreationError: null };
        }

        case "SHOW_UPLOAD_NEW_FILE_ERROR": {
            return { ...state, newModelCreationError: action.message }
        }

        default:
            return state;
    }
}