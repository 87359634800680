import repo from '../Repository';
import JobProgress from "../jobProgress";
import { showProcessingPane, reportProcessingProgress, showProcessingError } from "./processingActions";
import { fetchPanels } from "./panelsActions";
import { getModelSource, getCurrentFolder, isCornerModelUpload, State } from "../reducers/mainReducer";
import { AxiosError } from 'axios';
import { IForgeFileStorageObject } from '../entities/forgeFileStorageObject';
import { BasicResponse } from '../entities/responses';

type ShowUploadModelSourceType = {
    type: "SHOW_UPLOAD_MODEL_SOURCE";
    visible: boolean;
    cornerModel: boolean;
}

type ShowUploadCustomModelSourceType = {
    type: "SHOW_UPLOAD_CUSTOM_MODEL_SOURCE";
    visible: boolean;
}

type SelectModelSourceFileType = {
    type: "MODEL_SOURCE_FILE_SELECTED";
    file: File;
}

type SetProductionLodType = {
    type: "SET_PRODUCTION_LOD";
    value: boolean;
}

type ShowUploadFileErrorType = {
    type: "SHOW_UPLOAD_NEW_FILE_ERROR";
    message: string;
}

export type PanelAutocadSourceModelActionType = ShowUploadModelSourceType | ShowUploadCustomModelSourceType | SelectModelSourceFileType | SetProductionLodType | ShowUploadFileErrorType;

export const showUploadModelSource = (visible: boolean, cornerModel?: boolean): ShowUploadModelSourceType => {
    return {
        type: "SHOW_UPLOAD_MODEL_SOURCE",
        visible,
        cornerModel: !!cornerModel
    }
}

export const showUploadCustomModelSource = (visible: boolean): ShowUploadCustomModelSourceType => {
    return {
        type: "SHOW_UPLOAD_CUSTOM_MODEL_SOURCE",
        visible
    }
}

export const selectModelSourceFile = (file: File): SelectModelSourceFileType => {
    return {
        type: "MODEL_SOURCE_FILE_SELECTED",
        file
    }
}

export const setProductionLod = (value: boolean): SetProductionLodType => {
    return {
        type: "SET_PRODUCTION_LOD",
        value
    }
}

export const showUploadFileError = (message: string): ShowUploadFileErrorType => {
    return {
        type: "SHOW_UPLOAD_NEW_FILE_ERROR",
        message
    }
}

export const uploadNewPanelModel = (file: File) => async (dispatch: (type: any) => void, getState: () => State) => {
    dispatch(showProcessingPane(true));

    dispatch(reportProcessingProgress("Uploading..."));

    const state = getState();

    const folder = getCurrentFolder(state);

    if (folder === null)
        throw new Error("Invalid state! Folder is NULL!");

    const isCornerModel = isCornerModelUpload(state);

    const modelSource = getModelSource(state);

    let uploadFileResponse: IForgeFileStorageObject;

    try {
        uploadFileResponse = await repo.uploadFileToBucket(file);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            uploadFileResponse = e.response.data;
        else {
            dispatch(showProcessingPane(false));
            dispatch(showUploadFileError("Server error. Repeat operation later"));

            return;
        }
    }

    dispatch(showUploadModelSource(false));
    dispatch(reportProcessingProgress("Pending..."));
    const currentPanelId = uploadFileResponse.id;

    const onProgress = function (panelId: string, message: string) {
        if (panelId === currentPanelId)
            dispatch(reportProcessingProgress(message));
    }

    const onCompleted = function (panelId: string) {
        if (panelId !== currentPanelId)
            return;

        dispatch(fetchPanels(folder.id, currentPanelId));
        dispatch(showProcessingPane(false));
        dispatch(showUploadModelSource(false));
    }

    const onFailed = function (panelId: string, message: string) {
        if (panelId !== currentPanelId)
            return;

        dispatch(showProcessingPane(false));

        dispatch(showProcessingError(message));
    }

    const connectionId = await JobProgress.subscribeToProcessModelsEvents(onProgress, onCompleted, onFailed);

    let startPanelProcessingResult: BasicResponse;

    const productionLod = modelSource.productionLod;

    try {
        if (isCornerModel) {
            startPanelProcessingResult = await repo.startGeneratingCornerPanelFromDwg(uploadFileResponse, folder.id, productionLod, connectionId);
        }
        else {
            startPanelProcessingResult = await repo.startGeneratingPanelFromDwg(uploadFileResponse, folder.id, productionLod, connectionId);
        }
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            startPanelProcessingResult = e.response.data;
        else {
            startPanelProcessingResult = {
                isSuccess: false,
                message: "Server communication error. Try later, please"
            }
        }
    }

    if (!startPanelProcessingResult.isSuccess) {
        dispatch(showProcessingError(startPanelProcessingResult.message));

        dispatch(showProcessingPane(false));

        return;
    }
}

export const uploadCustomInventorModel = (file: File, assemblyName: string) => async (dispatch: (type: any) => void, getState: () => State) => {
    dispatch(showProcessingPane(true));

    dispatch(reportProcessingProgress("Uploading..."));

    const state = getState();

    const folder = getCurrentFolder(state);

    if (folder === null)
        throw new Error("Invalid state! Folder is NULL!");

    let uploadFileResponse: IForgeFileStorageObject;

    try {
        uploadFileResponse = await repo.uploadCustomPanelModel(file);
    } catch (e: AxiosError | any) {
        dispatch(showProcessingPane(false));

        const message = e instanceof AxiosError && e.response?.data?.message ? e.response.data.message : "Server error. Repeat operation later";

        dispatch(showUploadFileError(message));

        return;
    }

    dispatch(showUploadCustomModelSource(false));
    dispatch(reportProcessingProgress("Pending..."));
    const currentPanelId = uploadFileResponse.id;

    const onProgress = function (panelId: string, message: string) {
        if (panelId === currentPanelId)
            dispatch(reportProcessingProgress(message));
    }

    const onCompleted = function (panelId: string) {
        if (panelId !== currentPanelId)
            return;

        dispatch(fetchPanels(folder.id, currentPanelId));
        dispatch(showProcessingPane(false));
        dispatch(showUploadModelSource(false));
    }

    const onFailed = function (panelId: string, message: string) {
        if (panelId !== currentPanelId)
            return;

        dispatch(showProcessingPane(false));

        dispatch(showProcessingError(message));
    }

    const connectionId = await JobProgress.subscribeToProcessModelsEvents(onProgress, onCompleted, onFailed);
    
    let startPanelProcessingResult: BasicResponse;

    try {
        startPanelProcessingResult = await repo.startGeneratingCustomPanel(uploadFileResponse, assemblyName, folder.id, connectionId);
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            startPanelProcessingResult = e.response.data;
        else {
            startPanelProcessingResult = {
                isSuccess: false,
                message: "Server communication error. Try later, please"
            }
        }
    }

    if (!startPanelProcessingResult.isSuccess) {
        dispatch(showProcessingError(startPanelProcessingResult.message));

        dispatch(showProcessingPane(false));

        return;
    }
}

export const createPanelsFromTestInputs = () => async (dispatch: (type: any) => void) => {
    dispatch(showProcessingPane(true));

    dispatch(reportProcessingProgress("Enqueing panels..."));

    let operationResult: BasicResponse;

    try {
        operationResult = await repo.createPanelsFromTestInputs();
    } catch (e: AxiosError | any) {
        if (e instanceof AxiosError && e.response?.data?.message)
            operationResult = e.response.data;
        else
            operationResult = {
                isSuccess: false,
                message: "Server communication error. Try later, please"
            }
    }

    dispatch(showProcessingPane(false));

    if (!operationResult.isSuccess)
        dispatch(showProcessingError(operationResult.message));
}