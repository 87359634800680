import { ReportsActionsType } from "../actions/reportsActions";

export type State = {
    isVisible: boolean;
    report: string | null;
}

const initialState: State = {
    isVisible: false,
    report: null
}

export const isReportDialogVisible = function (state: State): boolean {
    return state.isVisible;
}

export const getReport = function (state: State): string | null {
    return state.report;
}

export default function (state: State = initialState, action: ReportsActionsType): State {
    switch (action.type) {
        case "FOLDER_REPORTS_SHOW":
            return { ...state, isVisible: action.visible, report: action.report };

        default:
            return state;
    }
}