import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
import { State, getFolderStack, getSelectedPanel, mainReducer } from './reducers/mainReducer';
import { PanelInspectorIntegrationApp } from './PanelInspectorIntegrationApp';
import { PanelInspectorMobileApp } from './PanelInspectorMobileApp';
import { getLocalApplicationMetadata } from './appMetadata';
import "@weave-design/fonts/build/ArtifaktElement.css";
import 'bootstrap/dist/css/bootstrap.css';

const appMetadata = getLocalApplicationMetadata();

if (appMetadata.environment === "production")
    Sentry.init({
        dsn: "https://c0becdd748dd261889e9a1ff41b8f7a2@o4506003617808384.ingest.sentry.io/4506019385442304",
        environment: "webapp",
        defaultIntegrations: false,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: `dextall-panels-generator@${appMetadata.version}`,
        integrations: [
            Sentry.breadcrumbsIntegration({ console: false }),
            Sentry.globalHandlersIntegration(),
            Sentry.dedupeIntegration(),
            Sentry.functionToStringIntegration(),
            Sentry.replayIntegration({ mutationLimit: 500 }),
            Sentry.captureConsoleIntegration({ levels: ["warn", "error"] })]
    });

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state: State) => {
        const folders = getFolderStack(state);
        const selectedPanel = getSelectedPanel(state);

        return {
            folders, 
            selectedPanel: selectedPanel ? {
                id: selectedPanel.id,
                typeName: selectedPanel.typeName,
                instanceName: selectedPanel.instanceName,
                productionLod: selectedPanel.productionLod,
                modelUrl: selectedPanel.modelUrl
            } : null
        };
    },

    actionTransformer: (action) => {
        return action.type === "ADD_LOG" || action.type === "ADD_ERROR" ? action : null;
    }
})

const rootElement = document.getElementById('root')!;

const root = createRoot(rootElement);

root.render(
    <Router>
        <Routes>
            <Route path='/' element={<Provider store={configureStore({ reducer: mainReducer, enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer) })}>
                <App />
            </Provider>} />

            <Route path='/panels-inspector' element={<PanelInspectorIntegrationApp />} />
            <Route path='/mobile/panels-inspector' element={<PanelInspectorMobileApp />} />
        </Routes>
    </Router>
);
