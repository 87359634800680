import { PanelInstancesActionType } from "../actions/panelInstancesActions";

export type State = {
    showCreateInstancesDialog: boolean;
    instanceCreationError: string | null;
}

export const initialState: State = {
    showCreateInstancesDialog: false,
    instanceCreationError: null
}

export const isInstancesDialogCreationVisible = function (state: State) {
    return state.showCreateInstancesDialog;
}

export const getInstancesCreationError = function (state: State) {
    return state.instanceCreationError;
}

export default function (state: State = initialState, action: PanelInstancesActionType): State {
    switch (action.type) {
        case "SHOW_PANEL_INSTANCES_CREATION_DIALOG": {
            return { ...state, showCreateInstancesDialog: action.visible };
        }

        case "SHOW_PANEL_INSTANCES_CREATION_ERROR": {
            return { ...state, instanceCreationError: action.message };
        }

        default:
            return state;
    }
}