interface IComponentProps {
    models: ({
        title: string;
        modelUrl: string;
    })[];
    selectedViewable: number;
    setSelectedViewable: (index: number) => void;
}

export const PanelViewablesSelector = (props: IComponentProps) => {
    const viewables = props
        .models
        .map((x, i) => (<li 
            key={`forge-viewable-${i}`} 
            style={{fontWeight: i === props.selectedViewable ? "bold" : "normal", cursor: "pointer" }} 
            onClick={() => props.setSelectedViewable(i)} >{x.title}</li>))

    return (<div className="fullheight" style={{ overflowY: "scroll", overflowX: "hidden" }}>
        <ul>
            {viewables}
        </ul>
    </div>)
}