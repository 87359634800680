import { PanelActionTypes } from '../actions/panelsActions';
import { IBomRow } from '../entities/bomRow';
import { IFolder } from '../entities/folder';
import { IPanel, IPanelDrawings } from '../entities/panel';

export type FolderEditorState = "none" | "new" | "edit";

export type State = {
    panels: IPanel[];
    folders: IFolder[];
    foldersStack: IFolder[];
    drawings: IPanelDrawings[];
    selectedPanel: IPanel | null;
    folderEditorState: FolderEditorState;
    editorFolder: IFolder | null;
    bom: IBomRow[];
    bomVisible: boolean;
    bomExcelFileUrl: string | null;
}

export const initialState: State = {
    panels: [],
    folders: [],
    foldersStack: [],
    drawings: [],
    selectedPanel: null,
    folderEditorState: "none",
    editorFolder: null,
    bom: [],
    bomVisible: false,
    bomExcelFileUrl: null
}

export const getPanels = function (state: State): IPanel[] {
    return state.panels;
}

export const getSelectedPanel = function (state: State): IPanel | null {
    return state.selectedPanel;
}

export const getFolders = function (state: State): IFolder[] {
    return state.folders;
}

export const isTopFolder = function (state: State): boolean {
    return state.foldersStack.length === 0;
}

export const getCurrentFolder = function (state: State): IFolder | null {
    return state.foldersStack.length > 0 ? state.foldersStack[state.foldersStack.length - 1] : null;
}

export const getFolderStack = function (state: State): IFolder[] {
    return state.foldersStack;
}

export const getFolderEditorState = function (state: State): FolderEditorState {
    return state.folderEditorState;
}

export const getEditorFolder = function (state: State): IFolder | null {
    return state.editorFolder;
}

export const getPanelDrawings = function (state: State): IPanelDrawings[] {
    return state.drawings;
}

export const getBomExcelFileUrl = function (state: State): string | null {
    return state.bomExcelFileUrl;
}

export const getBom = function (state: State): IBomRow[] {
    return state.bom;
}

export const isBomVisible = function (state: State): boolean {
    return state.bomVisible;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state: State = initialState, action: PanelActionTypes): State {
    switch (action.type) {
        case "SET_PANELS": {
            return { ...state, panels: action.panels };
        }

        case "SET_SELECTED_PANEL": {
            return { ...state, selectedPanel: state.panels.find(x => x.id === action.panelId) || null, drawings: [] };
        }

        case "SET_FOLDERS": {
            return { ...state, folders: action.folders };
        }

        case "SET_SELECTED_FOLDER": {
            const foldersStack = Array.from(state.foldersStack);

            if (foldersStack.length === 0 || foldersStack[foldersStack.length - 1].id !== action.folder.id)
                foldersStack.push(action.folder);

            return { ...state, foldersStack, folders: [], panels: [], bom: [], bomVisible: false, bomExcelFileUrl: null }
        }

        case "SET_TOP_FOLDER_SELECTED": {
            return { ...state, foldersStack: [], folders: [], panels: [] }
        }

        case "SELECT_FOLDER_FROM_STACK": {
            const index = state.foldersStack.findIndex(x => x.id === action.folder.id) + 1;

            const foldersStack = Array.from(state.foldersStack);

            foldersStack.splice(index);

            return { ...state, foldersStack, folders: [], panels: [] }
        }

        case "REMOVE_PANEL": {
            const panels = [...state.panels].filter(x => x.id !== action.panel.id);

            return { ...state, panels }
        }

        case "SET_FOLDER_EDITOR_STATE": {
            return { ...state, folderEditorState: action.state, editorFolder: null }
        }

        case "SET_FOLDER_FOR_EDIT": {
            return { ...state, folderEditorState: "edit", editorFolder: action.folder }
        }

        case "SET_FOLDER_NAME": {
            const folders = state
                .folders
                .map(x => {
                    if (x.id !== action.folderId)
                        return x;

                    return { ...x, name: action.name }
                })

            return { ...state, folders }
        }

        case "REMOVE_FOLDER": {
            const folders = [...state.folders].filter(x => x.id !== action.folderId);

            return { ...state, folders }
        }

        case "SET_PANEL_DRAWINGS": {
            return { ...state, drawings: action.drawings };
        }

        case "SET_PANEL_INSTANCES_COUNT": {
            const getPanel = (panel: IPanel) => {
                if (panel.id !== action.panelId)
                    return panel;

                return { ...panel, instancesCount: action.instancesCount }
            }

            const panels = state.panels.map(x => getPanel(x));

            const selectedPanel = state.selectedPanel ? getPanel(state.selectedPanel) : null;

            return { ...state, panels, selectedPanel }
        }

        case "SET_FOLDER_BOM_EXCEL_FILE_URL": {
            return { ...state, bomExcelFileUrl: action.url }
        }

        case "SET_BOM_REPORT": {
            return { ...state, bom: action.items, bomVisible: action.visible }
        }

        default:
            return state;
    }
}