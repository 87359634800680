import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { IFolder } from "../entities/folder";
import { setFolderEditorState, createNewFolder, updateFolder } from "../actions/panelsActions";
import { getFolderEditorState, getCurrentFolder, getEditorFolder, State } from "../reducers/mainReducer";
import { FolderEditorState } from "../reducers/panelsReducer";
import { ConfirmDialog } from "./confirmDialog";
import Label from '@weave-design/label';
import Input from '@weave-design/input';

interface IComponentProps {
    state: FolderEditorState;
    parentFolder: IFolder | null;
    editorFolder: IFolder | null;
    setFolderEditorState: (state: FolderEditorState) => void;
    createNewFolder: (parentFolder: IFolder | null, name: string) => void;
    updateFolder: (folderId: string, newName: string) => void;
}

export const FolderEditor = (props: IComponentProps) => {
    const minLength = 3;
    const [folderName, setFolderName] = useState("");

    useEffect(() => {
        setFolderName(props.editorFolder?.name || "");
    }, [props.state, props.parentFolder, props.editorFolder])

    if (props.state === "none")
        return (null);

    const save = () => {
        if (folderName.length < minLength)
            return;

        switch (props.state) {
            case "new": {
                const name = folderName;
                props.setFolderEditorState("none")
                props.createNewFolder(props.parentFolder, name);
                break;
            }

            case "edit": {
                const folderId = props.editorFolder!.id;
                const name = folderName;
                props.setFolderEditorState("none");
                props.updateFolder(folderId, name)
                break;
            }
        }
    }

    return (<ConfirmDialog
        isOpened={true}
        title={props.state === "edit" ? "Edit folder" : "New folder"}
        confirmButtonTitle={props.state === "edit" ? "Update" : "Create"}
        onCancel={() => props.setFolderEditorState("none")}
        onConfirm={() => save()}
        confirmDisabled={folderName.length < minLength}
        promptContainerStyles={{height: "70px"}}
        windowStyles={{height: "240px"}}>
        <Label variant="top">Folder name:</Label>
        <Input variant="box" value={folderName} onChange={(data: React.ChangeEvent<HTMLInputElement>) => setFolderName(data.target.value) }/>
    </ConfirmDialog>)
}

export default connect(function (store: State) {
    return {
        state: getFolderEditorState(store),
        parentFolder: getCurrentFolder(store),
        editorFolder: getEditorFolder(store)
    }
}, { setFolderEditorState, createNewFolder, updateFolder })(FolderEditor)