import { useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { getBomExcelFileUrl, State } from "../reducers/mainReducer";

interface IComponentProps {
    bomDownloadUrl: string | null;
}

const BomDownload = (props: IComponentProps) => {
    const link = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (!props.bomDownloadUrl)
            return;

        const linkElement = link.current!;

        linkElement.click();
    }, [props.bomDownloadUrl]);

    return (<div style={{ display: "none" }}>
        <a href={props.bomDownloadUrl || ""} ref={link} />
    </div>)
}

export default connect(function (store: State) {
    return {
        bomDownloadUrl: getBomExcelFileUrl(store)
    };
}, {})(BomDownload);