import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchTopFolders, selectPanel, selectFolder, deletePanel, editFolder, deleteFolder, updatePanelInstancesCount } from "../actions/panelsActions";
import { getPanels, getFolders, State, getProfile } from "../reducers/mainReducer";
import { State as ProfileState } from '../reducers/profileReducer'
import { IPanel } from '../entities/panel';
import { PanelCard } from './panelCard';
import { IFolder } from '../entities/folder';
import { FolderCard } from './folderCard';
import { ConfirmDialog } from './confirmDialog';
import { PanelTitle } from './panelTitle';
import "./panels.css";
import "bootstrap/dist/css/bootstrap.min.css"

interface IComponentProps {
    panels: IPanel[];
    folders: IFolder[];
    profile: ProfileState;
    fetchTopFolders: () => void;
    selectPanel: (panelId?: string) => void;
    selectFolder: (folder: IFolder) => void;
    editFolder: (folder: IFolder) => void;
    deletePanel: (panel: IPanel) => void;
    deleteFolder: (folderId: string) => void;
    updatePanelInstancesCount: (panelId: string, instancesCount: number) => void;
}

const Panels = (props: IComponentProps) => {
    const [panelToDelete, setPanelToDelete] = useState<IPanel | null>(null);
    const [folderToDelete, setFolderToDelete] = useState<IFolder | null>(null);

    useEffect(() => {
        props.fetchTopFolders();
    }, []);

    const queryDeletePanel = (panel: IPanel) => setPanelToDelete(panel);
    const queryDeleteFolder = (folder: IFolder) => setFolderToDelete(folder);

    const hideEditButtons = props.profile.isPanelInspectorUser;

    const folders = props.folders.map(x => (<FolderCard
        folder={x}
        select={props.selectFolder.bind(null, x)}
        edit={props.editFolder.bind(null, x)}
        delete={queryDeleteFolder.bind(null, x)}
        hideEditButtons={hideEditButtons}
        key={`folder-${x.id}`} />));

    const panels = props.panels.map(x => (<PanelCard panel={x}
        select={props.selectPanel.bind(null, x.id)}
        deletePanel={queryDeletePanel.bind(null, x)}
        updateInstancesCount={props.updatePanelInstancesCount.bind(null, x.id)}
        hideEditButtons={hideEditButtons}
        key={`panel-${x.id}`} />));

    const deletePanel = () => {
        const panel = panelToDelete!;

        setPanelToDelete(null);

        props.deletePanel(panel);
    }

    const deleteFolder = () => {
        const folder = folderToDelete!;

        setFolderToDelete(null);

        props.deleteFolder(folder.id);
    }

    const confirmDialogWindowStyle: React.CSSProperties = { width: "500px", height: "220px" };
    const confirmDialogPromptContainerStyle: React.CSSProperties = { height: "50px" }

    return (<>
        <div className="container panels">
            <div className="row">
                {folders}
                {panels}
            </div>
        </div>

        {panelToDelete && <ConfirmDialog
            isOpened={true}
            title="Delete panel"
            confirmButtonTitle="Delete"
            deleteButtonClassName="red"
            onCancel={() => setPanelToDelete(null)}
            onConfirm={() => deletePanel()}
            windowStyles={confirmDialogWindowStyle}
            promptContainerStyles={confirmDialogPromptContainerStyle}
        >Are you sure you want to delete <PanelTitle panel={panelToDelete} />{panelToDelete.panelType === 2 ? " corner" : ""} panel?</ConfirmDialog>}

        {folderToDelete && <ConfirmDialog
            isOpened={true}
            title="Delete folder"
            confirmButtonTitle="Delete"
            deleteButtonClassName="red"
            onCancel={() => setFolderToDelete(null)}
            onConfirm={() => deleteFolder()}
            windowStyles={confirmDialogWindowStyle}
            promptContainerStyles={confirmDialogPromptContainerStyle}
        >Are you sure you want to delete <strong>{folderToDelete.name}</strong> folder?</ConfirmDialog>}
    </>);
}

export default connect(function (store: State) {
    return {
        folders: getFolders(store),
        panels: getPanels(store),
        profile: getProfile(store)
    };
}, { fetchTopFolders, selectPanel, selectFolder, deletePanel, editFolder, deleteFolder, updatePanelInstancesCount })(Panels);