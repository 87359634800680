import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@weave-design/modal';
import ProgressBar from '@weave-design/progress-bar';
import { isProcessingPaneVisible, getProcessingMessage, State } from "../reducers/mainReducer";
import merge from "lodash.merge";
import "./processingPane.css";

interface IComponentProps {
    isVisible: boolean;
    message: string | null;
}

export class ProcessingPane extends Component<IComponentProps> {
    render() {
        const modalStyles = (styles: any) =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "400px",
                        height: "200px",
                        top: "calc(50% - 100px)"
                    },
                    bodyContent: {
                        overflow: "hidden" // no scrollbar
                    }
                }
            });

        return (<React.Fragment>
            <div className="processing-pane">
                <Modal
                    open={this.props.isVisible} title="Processing..." stylesheet={modalStyles}>
                    <div className="processingProgressPane">
                        <div className="processingProgressContent">
                            <div className="processingProgressImage" style={{ backgroundImage: "url('/Assembly_icon.svg')" }} />
                            <div className="processingProgressAction" style={{ fontWeight: "bold" }}>
                                <ProgressBar className="processingProgressBar" />
                            </div>
                        </div>
                        <div className="processingProgressTimePrompt">This operation could take several minutes</div>
                        {this.props.message && <div className="processingProgressStatusPane"><strong>Status</strong>: {this.props.message}</div>}
                    </div>
                </Modal>
            </div>
        </React.Fragment>)
    }
}

export default connect(function (store: State) {
    return {
        isVisible: isProcessingPaneVisible(store),
        message: getProcessingMessage(store)
    }
}, {})(ProcessingPane)