import * as Sentry from "@sentry/react";
import { IUser } from '../entities/user';
import { addError, addLog } from './notificationActions';
import repo from '../Repository';

const actionTypes = {
    PROFILE_LOADED: 'PROFILE_LOADED'
};

export default actionTypes;

export type ProfileActionsType = {
    type: "PROFILE_LOADED",
    profile: IUser,
    isLoggedIn: "logged" | "not-logged"
}

export const detectToken = () => async (dispatch: (type: any) => void) => {
    const savedToken = localStorage["dextallpanels.portaluser.token"];

    if (savedToken) {
        repo.setAccessToken(savedToken);

        const isTokenValid = await repo.validateAccessToken();

        if (!isTokenValid)
            repo.forgetAccessToken();
    }
    else {
        repo.forgetAccessToken();
    }

    dispatch(loadProfile());
}

export const storeToken = (token: string) => {
    localStorage["dextallpanels.portaluser.token"] = token;
}

export const cleanToken = () => {
    delete localStorage["dextallpanels.portaluser.token"];
}

export const loadProfile = () => async (dispatch: (type: any) => void) => {
    dispatch(addLog('Load profile invoked'));
    try {
        const profile = await repo.loadProfile();
        dispatch(addLog('Load profile received'));
        const isLoggedIn = repo.hasAccessToken();
        dispatch(updateProfile(profile, isLoggedIn ? "logged" : "not-logged"));

        if (isLoggedIn)
            Sentry.setUser({ email: profile.name })
        else
            Sentry.setUser(null);
    } catch (error) {
        console.log("TODO process error")
        console.log(error);
        dispatch(addError('Failed to get profile. (' + error + ')'));
    }
};

export const updateProfile = (profile: IUser, isLoggedIn: "logged" | "not-logged"): ProfileActionsType => {
    return {
        type: "PROFILE_LOADED",
        profile,
        isLoggedIn
    };
};