import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from "@weave-design/typography";
import { setFolderEditorState, showFolderBomReport } from "../actions/panelsActions";
import { showUploadModelSource, createPanelsFromTestInputs, showUploadCustomModelSource } from "../actions/panelAutocadSourceModelActions";
import { showPanelsInstancesCreationDialog } from "../actions/panelInstancesActions";
import { State as ProfileState } from '../reducers/profileReducer'
import { getProfile, isTopFolder, getCurrentFolder, isLoggedIn, State } from '../reducers/mainReducer';
import { UserDetails } from './userDetails';
import { offsetPanelHorizontal, offsetContainerVertical } from "@weave-design/flyout"
import { logout } from "../actions/loginActions";
import TopNav, { Logo, LogoText, Interactions, NavAction, Separator } from '@hig/top-nav';
import Button from "@weave-design/button"
import styled from 'styled-components';
import { Profile24, Grid24, CaretLeft24, AddFolder24, Building24, Report24, TreeView24, DocumentManagement24 } from "@weave-design/icons";
import { ApplicationUserState } from '../entities/user';
import { FolderEditorState } from '../reducers/panelsReducer';
import { IFolder } from '../entities/folder';
import { showFolderReport } from '../actions/reportsActions';
import PanelsStickyNotesGenerator from './panelsStickyNotesGenerator';


const PlaceCenterContainer = styled.div`
    align-items: center;
    display: flex;
  `;

interface IComponentProps {
    profile: ProfileState;
    isLoggedIn: ApplicationUserState;
    isTopFolder: boolean;
    folder: IFolder | null;
    isRobot: boolean;
    children?: any;

    showUploadModelSource: (visible: boolean, cornerModel?: boolean) => void,
    showUploadCustomModelSource: (visible: boolean) => void,
    createPanelsFromTestInputs: () => void,
    showFolderReport: (folderId: string) => void;
    setFolderEditorState: (state: FolderEditorState) => void;
    showFolderBomReport: (folderId: string) => void;
    showPanelsInstancesCreationDialog: (visible: boolean) => void;
}

export class Toolbar extends Component<IComponentProps> {
    signout() {
        logout();
    }

    render() {
        return (<TopNav
            logo={
                <Logo label="Dextall panels">
                    <PlaceCenterContainer>
                        <img src={"logo.svg"} alt="" height="28px" />
                        <div style={{ paddingLeft: '10px', paddingTop: '5px' }}>
                            <LogoText>
                                <Typography style={{ fontSize: '22px', textAlign: 'right' }}><strong>Panels generator</strong></Typography>
                            </LogoText>
                        </div>
                    </PlaceCenterContainer>
                </Logo>
            }
            rightActions={
                <React.Fragment>
                    <PlaceCenterContainer>
                        {this.props.children}
                    </PlaceCenterContainer>
                    <Interactions>
                        {this.props.isLoggedIn === "logged" &&
                            <>
                                {this.props.isRobot && <>
                                    <Button title="Create panels" icon={<Building24 />} onClick={() => this.props.createPanelsFromTestInputs()} />
                                    {!this.props.isTopFolder &&
                                        <Button
                                            title="User Report"
                                            type="secondary"
                                            icon={<Report24 />} style={{ marginLeft: "10px" }}
                                            onClick={() => this.props.showFolderReport(this.props.folder!.id)} />}
                                </>}

                                {!this.props.isRobot && !this.props.profile.isPanelInspectorUser && <>
                                    {!this.props.isTopFolder && <>
                                        <Button title="New panel" icon={<Grid24 />} onClick={() => this.props.showUploadModelSource(true)} />
                                        <Button title="New corner" icon={<CaretLeft24 />} style={{ marginLeft: "10px" }} onClick={() => this.props.showUploadModelSource(true, true)} />
                                        <Button title="Custom panel" icon={<DocumentManagement24 />} style={{ marginLeft: "10px" }} onClick={() => this.props.showUploadCustomModelSource(true)} />

                                        <Separator />

                                        <Button title="Make elements" icon={<TreeView24 />} style={{ marginLeft: "10px", marginRight: "10px" }} onClick={() => this.props.showPanelsInstancesCreationDialog(true)} />

                                        <Separator />
                                    </>}
                                    <Button title="New folder" type="secondary" icon={<AddFolder24 />} style={{ marginLeft: "10px", marginRight: "10px" }} onClick={() => this.props.setFolderEditorState("new")} />

                                    {this.props.folder && <>
                                        <Separator />
                                        <Button title="BOM" type="secondary" icon={<Report24 />} style={{ marginLeft: "10px", marginRight: "10px" }} onClick={() => this.props.showFolderBomReport(this.props.folder!.id)} />
                                        <PanelsStickyNotesGenerator />
                                    </>}
                                </>}

                                <Separator />

                                <NavAction title="User profile" icon={<Profile24 />} alterCoordinates={(coordinates: any) => offsetPanelHorizontal(offsetContainerVertical(coordinates, 10), 6)}>
                                    <UserDetails profile={this.props.profile} logout={() => this.signout()}></UserDetails>
                                </NavAction>
                            </>}
                    </Interactions>
                </React.Fragment>
            } />)
    }
}

export default connect(function (store: State) {
    return {
        isTopFolder: isTopFolder(store),
        folder: getCurrentFolder(store),
        profile: getProfile(store),
        isLoggedIn: isLoggedIn(store),
        isRobot: !!getProfile(store)?.isRobot
    };
}, {
    showUploadModelSource, showUploadCustomModelSource, createPanelsFromTestInputs, showFolderReport,
    setFolderEditorState, showFolderBomReport, showPanelsInstancesCreationDialog
})(Toolbar);