import { useEffect, useState } from "react";
import { IInspectorCheckPoint, IInspectorNote } from "../entities/panelInspector"
import { CheckPoint, PanelInspectorCheck } from "./panelInspectorCheck";

type Props = {
    checkPoints: IInspectorCheckPoint[];
    inspectorNotes: IInspectorNote[];
    showCheckPointsNotes: (notes: IInspectorNote[]) => void;
}

export const PanelInspectorCheckList = ({ checkPoints, inspectorNotes, showCheckPointsNotes }: Props) => {
    const [checkPointsList, setCheckPointsList] = useState<CheckPoint[]>([]);

    useEffect(() => {
        const groupedNotes = inspectorNotes.reduce((acc, elem) => {
            const notesGroup = acc.get(elem.questionId) || [];

            notesGroup.push(elem);

            acc.set(elem.questionId, notesGroup);

            return acc;
        }, new Map<string, IInspectorNote[]>());

        const checks = checkPoints
            .map<CheckPoint>(x => {
                return { ...x, notes: groupedNotes.get(x.id) || [] }
            });

        setCheckPointsList(checks);
    }, [checkPoints, inspectorNotes]);

    const checkPointsElems = checkPointsList
        .map(x => <li key={`inspector-check-${x.id}`}><PanelInspectorCheck checkPoint={x} showCheckPointsNotes={showCheckPointsNotes} /></li>)

    return <ol>{checkPointsElems}</ol>
}