import Avatar from '@weave-design/avatar';
import Button from '@weave-design/button';
import { State } from '../reducers/profileReducer';
import "./userDetails.css";

interface IComponentProps {
    profile: State;
    logout: () => void;
}

export const UserDetails = (props: IComponentProps) => {
    return (
        <div>
            <div>
                <span className="user">USER</span>
                <Avatar className={"avatar-custom-style"} size="large" name={props.profile.name} image={props.profile.avatarUrl} />
            </div>
            <span className="username">{props.profile.name}</span>
            <Button className="auth-button" style={
                { width: '244px', height: '36px', borderRadius: '2px', border: '1px solid rgb(128, 128, 128)', margin: '12px' }}
                type="secondary"
                size="small"
                title="Sign Out"
                onClick={() => props.logout()}
            />
        </div>
    );
}