import { IInspectorNote } from "../entities/panelInspector";
import { PanelInspectorNoteAttachementCard } from "./panelInspectorNoteAttachementCard";

type Props = {
    additionalNote: IInspectorNote;
}

export const PanelInspectorAdditionalNote = ({ additionalNote }: Props) => {
    return <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{fontWeight: "600"}}>{additionalNote.text}</div>
        {additionalNote.attachments.length > 0 && <>
            <div>Photos:</div>
            <div style={{columnWidth: "80px", columnGap: "10px"}}>
                {additionalNote.attachments.map(x => <PanelInspectorNoteAttachementCard key={x} url={x} size={70} />)}
            </div>
        </>}
    </div>
}