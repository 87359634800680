import { IPanel, PanelType, getPanelLODTitle, getPanelTypeTitle, isCustomPanelType } from "../entities/panel"
import { PanelQRCode } from "./panelQRCode"
import { PanelTitle } from "./panelTitle"
import { round } from "./shared"

interface IComponentProps {
    panel: IPanel
}

export const PanelProperties = ({ panel }: IComponentProps) => {
    return (<>
        <PanelTitle panel={panel} />
        <PanelQRCode panel={panel} />
        <div className='parametersPreview'>LOD: {getPanelLODTitle(panel)}</div>
        <div className='parametersPreview'>Type: {getPanelTypeTitle(panel)}</div>
        <div className='parametersPreview'>Type id: {panel.typeName}</div>
        {panel.isInstancePanel && <div className="parametersPreview">Element id: {panel.instanceName}</div>}
        {!isCustomPanelType(panel.panelType) && <div className="parametersPreview">Thickness: {round(panel.panelThickness * 10, 2)}</div>}
        {panel.panelType === PanelType.Corner && <div className="parametersPreview">Angle: {round(panel.cornerPanelAngle, 2)}</div>}
        {panel.engineBuiltWith > 0 && <div className='parametersPreview'>Generator version: 1.0.{panel.engineBuiltWith}</div>}
    </>)
}